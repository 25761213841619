/* Global Styles */
//scroller
#style-1::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

//fin scroller

body {
    position: relative;
    height: 100%!important;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    background: #ccc;
    color: #4E5E6A;
}

html {
    height: 100%!important;
    position: relative;
}

a:hover,
a:active,
a:focus {
    outline: none!important;
}


/* Typography */

p {
    display: block;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 22px;
    margin: 0px 0px 10px 0px;
    font-style: normal;
    white-space: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 14px;
}

h5 {
    font-size: 12px;
}

h6 {
    font-size: 10px;
}

h3,
h4,
h5 {
    font-weight: 600;
}

.f-thin {
    font-weight: 300;
}

.f-normal {
    font-weight: normal;
}

.f-bold {
    font-weight: 600;
}

.f-s-10 {
    font-size: 10px;
}

.f-s-11 {
    font-size: 11px;
}

.f-s-12 {
    font-size: 12px;
}

.f-s-13 {
    font-size: 13px;
}

.f-s-14 {
    font-size: 14px;
}

.f-s-15 {
    font-size: 15px;
}

.f-s-16 {
    font-size: 16px;
}

.f-s-17 {
    font-size: 17px;
}

.f-s-18 {
    font-size: 18px;
}

.f-s-20 {
    font-size: 20px;
}

.f-s-22 {
    font-size: 22px;
}

.f-s-24 {
    font-size: 24px;
}

.f-s-26 {
    font-size: 26px;
}

.f-s-28 {
    font-size: 28px;
}

.f-s-30 {
    font-size: 30px;
}

.f-s-32 {
    font-size: 32px;
}

.text-xs {
    font-size: 10px;
}

.text-sm {
    font-size: 12px;
}

.text-md {
    font-size: 16px;
}

.text-lg {
    font-size: 32px;
}

.text-xl {
    font-size: 52px;
}

.text-xxl {
    font-size: 80px;
}

.f-white {
    color: #fff;
}

.f-gray {
    color: #ccc;
}

.f-gray-dark {
    color: #a2a2a2;
}

.f-black {
    color: #333;
}

.f-green {
    color: #22BAA0;
}

.f-blue {
    color: #127acb;
}

.f-lblue {
    color: #12AFCB;
}

.f-purple {
    color: #7a6fbe;
}

.f-red {
    color: #f25656;
}

.f-yellow {
    color: #f6d433;
}

.f-orange {
    color: #e79b2f;
}

.text-default {
    color: #ccc;
}

.text-primary {
    color: #7a6fbe;
}

.text-success {
    color: #22BAA0;
}

.text-info {
    color: #12AFCB;
}

.text-warning {
    color: #f6d433;
}

.text-danger {
    color: #f25656;
}


/* Icons */

.icon-xs {
    font-size: 12px;
}

.icon-sm {
    font-size: 14px;
}

.icon-md {
    font-size: 16px;
}

.icon-lg {
    font-size: 18px;
}

.icon-state-default {
    color: #ccc;
}

.icon-state-primary {
    color: #7a6fbe;
}

.icon-state-success {
    color: #22BAA0;
}

.icon-state-info {
    color: #12AFCB;
}

.icon-state-warning {
    color: #f6d433;
}

.icon-state-danger {
    color: #f25656;
}


/* Alert */

.alert {
    border-radius: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0;
}

.alert-success {
    background: #e5f3df;
}

.alert-info {
    background: #d3e6f0;
}

.alert-warning {
    background: #f6f2dd;
}

.alert-danger {
    background: #f1d9d9;
}

/* Custom Badges */

.badge {
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    border-radius: 12px !important;
    border-radius: 0px;
    font-weight: 300;
    font-size: 11px !important;
    text-align: center;
    vertical-align: middle;
    line-height: 11px;
    height: 18px;
    padding: 3px 6px 3px 6px;
    text-shadow: none !important;
}

.badge.badge-roundless {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.badge.badge-default {
    background: #f1f1f1;
    color: #aaa;
}

.badge.badge-primary {
    background: #7a6fbe;
    color: #FFF;
}

.badge.badge-info {
    background: #12AFCB;
    color: #FFF;
}

.badge.badge-success {
    background: #22BAA0;
    color: #FFF;
}

.badge.badge-danger {
    background: #f25656;
    color: #FFF;
}

.badge.badge-warning {
    background: #f6d433;
    color: #FFF;
}


/* Switchery */

.switch-sm .switchery {
    height: 15px;
    width: 25px;
}

.switch-sm .switchery > small {
    height: 15px;
    width: 15px;
}

.switch-md .switchery {
    height: 20px;
    width: 35px;
}

.switch-md .switchery > small {
    height: 20px;
    width: 20px;
}

.switch-lg .switchery {
    height: 25px;
    width: 40px;
}

.switch-lg .switchery > small {
    height: 25px;
    width: 25px;
}


/* CSS Utilities */
/* Margins & Paddings */

.no-s {
    margin: 0px!important;
    padding: 0px!important;
}

.no-m {
    margin: 0;
}

.no-p {
    padding: 0;
}

.m {
    margin: 15px;
}

.m-t-xxs {
    margin-top: 5px;
}

.m-r-xxs {
    margin-right: 5px;
}

.m-b-xxs {
    margin-bottom: 5px;
}

.m-l-xxs {
    margin-left: 5px;
}

.m-t-xs {
    margin-top: 10px;
}

.m-r-xs {
    margin-right: 10px;
}

.m-b-xs {
    margin-bottom: 10px;
}

.m-l-xs {
    margin-left: 10px;
}

.m-t-sm {
    margin-top: 15px;
}

.m-r-sm {
    margin-right: 15px;
}

.m-b-sm {
    margin-bottom: 15px;
}

.m-l-sm {
    margin-left: 15px;
}

.m-t-md {
    margin-top: 20px;
}

.m-r-md {
    margin-right: 20px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-l-md {
    margin-left: 20px;
}

.m-t-lg {
    margin-top: 25px;
}

.m-r-lg {
    margin-right: 25px;
}

.m-b-lg {
    margin-bottom: 25px;
}

.m-l-lg {
    margin-left: 25px;
}

.m-t-xxl {
    margin-top: 30px;
}

.m-r-xxl {
    margin-right: 30px;
}

.m-b-xxl {
    margin-bottom: 30px;
}

.m-l-xxl {
    margin-left: 30px;
}

.m-t-n-xxs {
    margin-top: -5px;
}

.m-r-n-xxs {
    margin-right: -5px;
}

.m-b-n-xxs {
    margin-bottom: -5px;
}

.m-l-n-xxs {
    margin-left: -5px;
}

.m-t-n-xs {
    margin-top: -10px;
}

.m-r-n-xs {
    margin-right: -10px;
}

.m-b-n-xs {
    margin-bottom: -10px;
}

.m-l-n-xs {
    margin-left: -10px;
}

.m-t-n-sm {
    margin-top: -15px;
}

.m-r-n-sm {
    margin-right: -15px;
}

.m-b-n-sm {
    margin-bottom: -15px;
}

.m-l-n-sm {
    margin-left: -15px;
}

.m-t-n-md {
    margin-top: -20px;
}

.m-r-n-md {
    margin-right: -20px;
}

.m-b-n-md {
    margin-bottom: -20px;
}

.m-l-n-md {
    margin-left: -20px;
}

.m-t-n-lg {
    margin-top: -25px;
}

.m-r-n-lg {
    margin-right: -25px;
}

.m-b-n-lg {
    margin-bottom: -25px;
}

.m-l-n-lg {
    margin-left: -25px;
}

.m-t-n-xxl {
    margin-top: -30px;
}

.m-r-n-xxl {
    margin-right: -30px;
}

.m-b-n-xxl {
    margin-bottom: -30px;
}

.m-l-n-xxl {
    margin-left: -30px;
}

.p {
    padding: 15px;
}

.p-h-xxs {
    padding-left: 5px;
    padding-right: 5px;
}

.p-h-xs {
    padding-left: 10px;
    padding-right: 10px;
}

.p-h-sm {
    padding-left: 15px;
    padding-right: 15px;
}

.no-p-h {
    padding-left: 0;
    padding-right: 0;
}

.no-p-v {
    padding-top: 0;
    padding-bottom: 0;
}

.p-h-md {
    padding-left: 20px;
    padding-right: 20px;
}

.p-h-lg {
    padding-left: 25px;
    padding-right: 25px;
}

.p-h-xxl {
    padding-left: 30px;
    padding-right: 30px;
}

.p-v-xxs {
    padding-top: 5px;
    padding-bottom: 5px;
}

.p-v-xs {
    padding-top: 10px;
    padding-bottom: 10px;
}

.p-v-sm {
    padding-top: 15px;
    padding-bottom: 15px;
}

.p-v-md {
    padding-top: 20px;
    padding-bottom: 20px;
}

.p-v-lg {
    padding-top: 25px;
    padding-bottom: 25px;
}

.p-v-xxl {
    padding-top: 30px;
    padding-bottom: 30px;
}


/* Borders */

.no-b {
    border: 0!important;
}

.b {
    border: 1px solid #f1f1f1;
}

.b-t {
    border-top: 1px solid #f1f1f1;
}

.b-r {
    border-right: 1px solid #f1f1f1;
}

.b-b {
    border-bottom: 1px solid #f1f1f1;
}

.b-l {
    border-left: 1px solid #f1f1f1;
}

.b-default {
    border-color: #f1f1f1;
}

.b-primary {
    border-color: #7a6fbe;
}

.b-info {
    border-color: #12AFCB;
}

.b-success {
    border-color: #22BAA0;
}

.b-warning {
    border-color: #f6d433;
}

.b-danger {
    border-color: #f25656;
}

.b-2 {
    border-size: 2px;
}

.b-3 {
    border-size: 3px;
}

.b-4 {
    border-size: 4px;
}

.b-n-t {
    border-top: 0px!important;
}

.b-n-r {
    border-right: 0px!important;
}

.b-n-b {
    border-bottom: 0px!important;
}

.b-n-l {
    border-left: 0px!important;
}


/* Radius */

.no-r {
    border-radius: 0px!important;
}

.r {
    border-radius: 2px;
}

.r-t-l {
    border-top-left-radius: 2px;
}

.r-t-r {
    border-top-right-radius: 2px;
}

.r-b-l {
    border-bottom-left-radius: 2px;
}

.r-b-r {
    border-bottom-right-radius: 2px;
}

.no-r-t-l {
    border-top-left-radius: 0!important;
}

.no-r-t-r {
    border-top-right-radius: 0!important;
}

.no-r-b-l {
    border-bottom-left-radius: 0!important;
}

.no-r-b-r {
    border-bottom-right-radius: 0!important;
}

/* Backgrounds */

.bg-n {
    background: transparent!important;
}

.bg-primary {
    background-color: #7a6fbe;
    color: #fff;
}

.bg-primary.dark {
    background-color: #6e63b4;
    color: #fff;
}

.bg-primary.light {
    background-color: #877cc7;
    color: #fff;
}

.bg-success {
    background-color: #22BAA0;
    color: #fff;
}

.bg-success.dark {
    background-color: #1da890;
    color: #fff;
}

.bg-success.light  {
    background-color: #32c6ad;
    color: #fff;
}

.bg-info {
    background-color: #12AFCB;
    color: #fff;
}

.bg-info.dark {
    background-color: #0ba0bb;
    color: #fff;
}

.bg-info.light {
    background-color: #20beda;
    color: #fff;
}

.bg-warning {
    background-color: #f6d433;
    color: #fff;
}

.bg-warning.dark {
    background-color: #f1ca11;
    color: #fff;
}

.bg-warning.light {
    background-color: #fada44;
    color: #fff;
}

.bg-danger {
    background-color: #f25656;
    color: #fff;
}

.bg-danger.dark {
    background-color: #e54f4f;
    color: #fff;
}

.bg-danger.light {
    background-color: #f66d6d;
    color: #fff;
}

.bg-dark {
    background-color: #333;
    color: #fff;
}

.bg-dark.dark {
    background-color: #2b2b2b;
    color: #fff;
}

.bg-dark.light {
    background-color: #3b3b3b;
    color: #fff;
}

.bg-light {
    background-color: #f1f1f1;
}

.bg-light.dark {
    background-color: #e5e5e5;
}

.bg-light.light {
    background-color: #f9f9f9;
}

.bg-gray {
    background-color: #ccc;
}

.bg-gray.dark {
    background-color: #b9b9b9;
}

.bg-gray.light {
    background-color: #d7d7d7;
}


/* Buttons */

.btn {
    font-size: 13px;
    border: 1px solid transparent;
    border-radius: 0;
    outline: none!important;
}

.btn-addon i {
    position: relative;
    float: left;
    width: 35px;
    height: 35px;
    margin: -7px -13px;
    margin-right: 12px;
    line-height: 34px;
    text-align: center;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background-color: rgba(0,0,0, 0.1);
}

.btn-rounded.btn-addon i {
    margin: -7px -16px;
    margin-right: 15px;
    border-radius: 50px;
}

.btn-rounded.btn-addon.btn-sm i {
    margin: -7px -16px;
    margin-right: 10px;
    border-radius: 50px;
}

.btn-lg {
    line-height: inherit;
}

.btn-lg.btn-addon i {
    width: 45px;
    height: 45px;
    margin: -11px -17px;
    margin-right: 16px;
    line-height: 45px;
}

.btn-sm.btn-addon i {
    width: 30px;
    height: 30px;
    margin: -6px -11px;
    margin-right: 10px;
    line-height: 30px;
}

.btn-xs.btn-addon i {
    width: 22px;
    height: 22px;
    margin: -2px -6px;
    margin-right: 5px;
    line-height: 22px;
    font-size: 10px;
}

.btn-default {
    background-color: #fff;
    color: #333;
    border: 1px solid #e5e5e5;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-group.open .btn-default.dropdown-toggle,
.btn.btn-default.active {
    background-color: #f1f1f1;
    border: 1px solid #e5e5e5;
}

.btn-primary {
    background-color: #7a6fbe;
    color: #fff;
    border: 1px solid transparent;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-group.open .btn-primary.dropdown-toggle,
.btn.btn-primary.active {
    background-color: #6a5fac;
    border: 1px solid transparent;
}

.btn-success {
    background-color: #22BAA0;
    color: #fff;
    border: 1px solid transparent;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-group.open .btn-success.dropdown-toggle,
.btn.btn-success.active {
    background-color: #1DB198;
    border: 1px solid transparent;
}

.btn-danger {
    background-color: #f25656;
    color: #fff;
    border: 1px solid transparent;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-group.open .btn-danger.dropdown-toggle,
.btn.btn-danger.active {
    background-color: #e14444;
    border: 1px solid transparent;
}

.btn-info {
    background-color: #12AFCB;
    color: #fff;
    border: 1px solid transparent;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-group.open .btn-info.dropdown-toggle,
.btn.btn-info.active {
    background-color: #08A7C3;
    border: 1px solid transparent;
}

.btn-warning {
    background-color: #f2ca4c;
    color: #fff;
    border: 1px solid transparent;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-group.open .btn-warning.dropdown-toggle,
.btn.btn-warning.active {
    background-color: #e8bf40;
    border: 1px solid transparent;
}

.btn-rounded {
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 50px;
}

.btn-group.open .dropdown-toggle {
    box-shadow: none;
}


/* Box Shadow */

.bs-n {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.bs-dark {
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .6);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, .6);
    box-shadow: 0 0 3px rgba(0, 0, 0, .6);
}

.bs-light {
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}

.bs-t {
    -webkit-box-shadow: 0 -1px 1px rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 -1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 -1px 1px rgba(0, 0, 0, .05);
}

.bs-r {
    -webkit-box-shadow: 1px 0 1px rgba(0, 0, 0, .05);
    -moz-box-shadow: 1px 0 1px rgba(0, 0, 0, .05);
    box-shadow: 1px 0 1px rgba(0, 0, 0, .05);
}

.bs-b {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.bs-l {
    -webkit-box-shadow: -1px 0 1px rgba(0, 0, 0, .05);
    -moz-box-shadow: -1px 0 1px rgba(0, 0, 0, .05);
    box-shadow: -1px 0 1px rgba(0, 0, 0, .05);
}


/* Width & Height */


.full-height {
    height: 100%!important;
}

.full-width {
    width: 100%!important;
}

.w-xxs {
    width: 50px;
}

.w-xs {
    width: 90px;
}

.w-sm {
    width: 140px;
}

.w {
    width: 200px;
}

.w-md {
    width: 240px;
}

.w-lg {
    width: 280px;
}

.w-xl {
    width: 330px;
}

.w-xxl {
    width: 370px;
}

.w-auto {
    width: auto;
}

.h-auto {
    height: auto;
}


/* Tooltip */

.tooltip-inner {
    border-radius: 0;
}


/* Panel */

.panel {
    -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    border: 0!important;
    margin-bottom: 25px;
    border-radius: 0;
}

@media (min-width: 768px) {
    .panel > .row > div:first-child {
        padding-left: 15px;
        padding-right: 0;
    }

    .panel > .row > div:last-child {
        padding-left: 0;
        padding-right: 15px;
    }
}

.panel .panel-heading {
    padding: 20px 20px 20px 20px;
    overflow: hidden;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0!important;
    height: 55px;
    font-size: 14px;
    font-weight: 600;
}

.panel .panel-heading .panel-title {
    font-size: 14px;
    float: left;
    margin: 0px;
    padding: 0px;
    font-weight: 600;
}

.panel .panel-heading .panel-title small {
    font-size: 80%;
}

.panel .panel-heading .panel-control {
    float: right;
    height: auto;
}

.panel .panel-heading .panel-control a {
    text-decoration: none;
    color: #ccc;
    margin-left: 5px;
    font-size: 14px;
    float: left;
}

.panel .panel-heading .panel-control a:hover {
    color: #a2a2a2;
}

.panel .panel-body {
    position: relative;
    padding: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.panel .panel-heading + .panel-body {
    padding: 0 20px 20px 20px;
}

.panel-default .panel-heading {
    background: #f1f1f1;
    border-color: #e7e7e7;
}

.panel-default .panel-heading .panel-control a {
    color: #bbb;
}

.panel-default .panel-heading .panel-control a:hover {
    color: #a2a2a2;
}

.panel-primary .panel-heading {
    background: #7a6fbe;
    color: #fff;
}

.panel-primary .panel-heading .panel-control a {
    color: #fff;
}

.panel-primary .panel-heading .panel-control a:hover {
    color: #f1f1f1;
}

.panel-success .panel-heading {
    background: #22BAA0;
    color: #fff;
}

.panel-success .panel-heading .panel-control a {
    color: #fff;
}

.panel-success .panel-heading .panel-control a:hover {
    color: #f1f1f1;
}

.panel-info .panel-heading {
    background: #12AFCB;
    color: #fff;
}

.panel-info .panel-heading .panel-control a {
    color: #fff;
}

.panel-info .panel-heading .panel-control a:hover {
    color: #f1f1f1;
}

.panel-warning .panel-heading {
    background: #f6d433;
    color: #fff;
}

.panel-warning .panel-heading .panel-control a {
    color: #fff;
}

.panel-warning .panel-heading .panel-control a:hover {
    color: #f1f1f1;
}

.panel-danger .panel-heading {
    background: #f25656;
    color: #fff;
}

.panel-danger .panel-heading .panel-control a {
    color: #fff;
}

.panel-danger .panel-heading .panel-control a:hover {
    color: #f1f1f1;
}

.panel-transparent {
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.panel-transparent .panel-heading .panel-control a {
    color: #ccc;
}

.panel-transparent .panel-heading .panel-control a:hover {
    color: #a2a2a2;
}

.panel-white {
    background: #fff;
}

.panel-white .panel-heading .panel-control a {
    color: #ccc;
}

.panel-white .panel-heading .panel-control a:hover {
    color: #a2a2a2;
}

.panel-purple {
    background: #7a6fbe;
}

.panel-purple .panel-heading {
    color: #fff;
}

.panel-purple .panel-body {
    color: #fff;
}

.panel-purple .panel-heading .panel-control a {
    color: #fff;
}

.panel-purple .panel-heading .panel-control a:hover {
    color: #f1f1f1;
}

.panel-green {
    background: #22BAA0;
}

.panel-green .panel-heading {
    color: #fff;
}

.panel-green .panel-body {
    color: #fff;
}

.panel-green .panel-heading .panel-control a {
    color: #fff;
}

.panel-green .panel-heading .panel-control a:hover {
    color: #f1f1f1;
}

.panel-blue {
    background: #12AFCB;
}

.panel-blue .panel-heading {
    color: #fff;
}

.panel-blue .panel-body {
    color: #fff;
}

.panel-blue .panel-heading .panel-control a {
    color: #fff;
}

.panel-blue .panel-heading .panel-control a:hover {
    color: #f1f1f1;
}

.panel-yellow {
    background: #f6d433;
}

.panel-yellow .panel-heading {
    color: #fff;
}

.panel-yellow .panel-body {
    color: #fff;
}

.panel-yellow .panel-heading .panel-control a {
    color: #fff;
}

.panel-yellow .panel-heading .panel-control a:hover {
    color: #f1f1f1;
}

.panel-red {
    background: #f25656;
}

.panel-red .panel-heading {
    color: #fff;
}

.panel-red .panel-body {
    color: #fff;
}

.panel-red .panel-heading .panel-control a {
    color: #fff;
}

.panel-red .panel-heading .panel-control a:hover {
    color: #f1f1f1;
}

.panel-placeholder {
    border: 2px dashed #ddd;
    margin-bottom: 20px;
}


/* Wells */

.well {
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #F6F6F6;
    border: 0;
}


/* Modals */

.modal {
    z-index: 9999;
}

.modal-content {
    -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 0;
}

.modal-header {
    padding: 20px 20px 20px 20px;
    overflow: hidden;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0!important;
    height: 55px;
    font-size: 14px;
    font-weight: 600;
}

.modal-body {
    position: relative;
    padding: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.modal-footer {
    position: relative;
    padding: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 0;
}

.modal-header + .modal-body {
    padding: 0 20px 20px 20px;
}

.modal-body + .modal-footer {
    padding: 0 20px 20px 20px;
}

.modal-open {
    overflow-y: auto!important;
}

.modal-open {
    padding: 0!important;
}

.modal-backdrop {
    z-index: 999999;
}

/* Pagination */

.pagination > li > a {
    color: #5B5B5B;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background: #22BAA0; 
    border-color: #22BAA0;
}


/* Progress Bars */

.progress {
    border-radius: 2px;
}

.progress-squared {
    border-radius: 0;
}

.progress-xs {
    height: 5px;
}

.progress-sm {
    height: 10px;
}

.progress-md {
    height: 15px;
}

.progress-lg {
    height: 25px;
}

.progress-bar {
    background-color: #7a6fbe;
}

.progress-bar-success {
    background-color: #22BAA0;
}

.progress-bar-info {
    background-color: #12AFCB;
}

.progress-bar-warning {
    background-color: #f6d433;
}

.progress-bar-danger {
    background-color: #f25656;
}


/* Tabs */

.tab-content {
    padding: 15px;
    background: #fff;
    
}

.nav-tabs > li > a {
    border-radius: 0!important;
    color: #777;
    border-bottom: 1px solid #DDDDDD;
}

.nav-tabs > li > a:hover {
    background: #f1f1f1;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #555;
    cursor: default;
    background-color: #fff;
    border-bottom-color: #fff;
}

.nav-pills > li > a {
    color: #777;
    border-radius: 0!important;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: #fff;
    background-color: #7a6fbe;
}

.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
    border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: none;
}

.tab-content > .active,
.pill-content > .active {
    display: block;
}

.tabs-below > .nav-tabs {
    border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
    margin-top: -1px;
    margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
    border-top-color: #ddd;
    border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
    border-color: #fff #ddd #ddd #ddd;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
    float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
    min-width: 74px;
    margin-right: 0;
    margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
    border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
    border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
    border-color: #ddd transparent #ddd #ddd;
    border-right-color: #ffffff;
}

.tabs-right > .nav-tabs {
    float: right;
    margin-left: 19px;
    border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
    margin-left: -1px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
    border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
    border-color: #ddd #ddd #ddd transparent;
    border-left-color: #ffffff;
}


/* Accordions */

.panel-group .panel-heading .panel-title > a {
    font-size: 14px;
}

.panel-group .panel-heading {
    font-size: 14px;
}

.panel-group .panel-heading  .panel-title > a {
    text-decoration: none;
}

.panel-group  .panel-heading {
    border-bottom: 0!important;
}

.panel > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: transparent;
}

.panel-group .panel-heading .panel-title {
    position: relative;
    width: 100%;
}

.panel-group .panel-heading .panel-title > a:after {
    font-family: 'FontAwesome';
    content: "\f067";
    position: absolute;
    right: 0;
    color: #777;
    height: 15px;
    top: 50%;
    margin-top: -7.5px;
}

.panel-group .panel-heading .panel-title > a.collapsed:after {
    content: "\f068";
}

.panel-group .panel-primary .panel-heading .panel-title > a:after,
.panel-group .panel-success .panel-heading .panel-title > a:after,
.panel-group .panel-info .panel-heading .panel-title > a:after,
.panel-group .panel-danger .panel-heading .panel-title > a:after,
.panel-group .panel-warning .panel-heading .panel-title > a:after {
    color: #fff;
}


/* jsTree */

.jstree-default .jstree-clicked {
    background: #f1f1f1;
    border-radius: 0;
    box-shadow: inset 0 0 1px #999;
}

.jstree-default li a:hover {
    background: #f1f1f1;
    border-radius: 0;
    box-shadow: inset 0 0 1px #999;
}



/* Labels */

.label.label-default {
    background: #fff;
    border: 1px solid #e5e5e5;
}

.label.label-success {
    background: #22BAA0;
}

.label.label-primary {
    background: #7a6fbe;
}

.label.label-info {
    background: #12AFCB;
}

.label.label-danger {
    background: #f25656;
}

.label.label-warning {
    background: #f6d433;
}


/* Forms */

label {
    font-size: 13px;
    font-weight: normal;
}

.controls label {
    display: block;
}

.form-control {
    background: #fff;
    border-radius: 0;
    border: 1px solid #dce1e4;
    box-shadow: none!important;
    font-size: 13px;
    padding: 6px 10px!important;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

input[disabled] {
    background: #f7f7f7;
    border-color: #d2d7db;
}

.form-control:focus {
    border: 1px solid #bdc5c9;
    background: #fff;
}

.help-block {
    margin-bottom: 5px;
}

.form-group.has-success .form-control,
.form-group.has-warning .form-control,
.form-group.has-error .form-control {
    box-shadow: none;
}

.form-group.has-success .form-control:focus {
    border: 1px solid #22BAA0;
    background: #fff;
}

.form-group.has-warning .form-control:focus {
    border: 1px solid #f6d433;
    background: #fff;
}

.form-group.has-error .form-control:focus {
    border: 1px solid #f25656;
    background: #fff;
}

.form-group.has-success label {
    color: #22BAA0;
}

.form-group.has-warning label {
    color: #f6d433;
}

.form-group.has-error label {
    color: #f25656;
}

label.error {
    color: #a94442;
    font-weight: bold;
    margin-top: 5px;
    float: left;
    font-size: 12px;
}

input.form-control.input-rounded {
    border-radius: 30px;
}

.input-group .btn {
    padding: 7px 12px;
}

ol.linenums {
    margin: 0;
}
/*
.select2-selection {
    border-radius: 0!important;
    border: 1px solid #dce1e4!important;
    box-shadow: none!important;
    font-size: 13px!important;
    padding: 6px 10px!important;
    height: 34px!important;
    -webkit-transition: all 0.2s ease-in-out!important;
    -moz-transition: all 0.2s ease-in-out!important;
    -o-transition: all 0.2s ease-in-out!important;
    transition: all 0.2s ease-in-out!important;
}

.select2-dropdown {
    border-radius: 0;
    border: 1px solid #dce1e4;
    border-top: 0;
    box-shadow: none!important;
    font-size: 13px;
    padding: 6px 10px!important;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    z-index: 9999;
}

.select2-dropdown.select2-dropdown--above {
    border: 1px solid #dce1e4;
    border-bottom: 0;
}

.select2-selection--multiple .select2-selection__choice {
    background: #f1f1f1!important;
    border-radius: 0!important;
    border: 1px solid #ccc!important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    line-height: inherit!important;
    padding: 0!important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container .select2-search--inline .select2-search__field {
    margin-top: 0!important;
}
*/
.popover {
    border: 1px solid #ebebeb;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
}

.popover-title {
    border-radius: 0;
}

.popover.top > .arrow {
    border-top-color: #ebebeb;
}

.popover.left > .arrow {
    border-left-color: #ebebeb;
}

.popover.right > .arrow {
    border-right-color: #ebebeb;
}

.popover.bottom > .arrow {
    border-bottom-color: #ebebeb;
}


/* Tables */
/*
.table > tbody > tr > td, 
.table > tbody > tr > th,
.table > tfoot > tr > td, 
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th,
.table td {
    padding: 15px!important;
}
*/
.dataTables_wrapper .dataTables_filter input,
.dataTables_wrapper .dataTables_length select {
    background: #fff;
    border-radius: 0;
    border: 1px solid #dce1e4;
    box-shadow: none!important;
    font-size: 13px;
    padding: 6px 10px!important;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.dataTables_wrapper .dataTables_filter input:focus {
    border: 1px solid #bdc5c9;
    background: #fff;
    outline: 0;
}

.dataTables_wrapper .dataTables_length select:focus {
    outline: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, 
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #5f5f5f!important;
    border: 1px solid #ebebeb;
    background: #f1f1f1!important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:active,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus {
    color: #5f5f5f!important;
    border: 1px solid transparent;
    background: #f7f7f7!important;
    box-shadow: none;
}

tr.group,
tr.group:hover {
    background-color: #ddd !important;
}


/* Wysiwyg */

.note-editor {
    border: 1px solid #DCE1E4;
    border-radius: 0;
}

.note-dialog .note-modal-form {
    margin: 0 15px 0 15px;
}

.note-editor .note-toolbar {
    border-bottom: 0;
    background: #fff;
    padding: 10px;
}

.note-popover .popover .popover-content>.btn-group, .note-toolbar>.btn-group {
    margin-top: 0;
}

.note-editor .note-editable {
    padding: 15px;
    padding-top: 10px;
    overflow: auto;
    outline: 0;
}

.note-editor .note-statusbar {
    background: transparent;
}

.note-editor .note-statusbar .note-resizebar {
    border: 0;
}


/* Center */

.center {
    margin: 0 auto;
    float: none!important;
    display: block;
}


/* Display */

.display-block {
    display: block;
}

.display-inline {
    display: inline-block;
}


/* Middle Box */

.box-middle {
    position: absolute;
    height: 400px;
    width: 400px;
    top: 50%;
    left: 50%;
    margin-left: -200px!important;
    margin-top: -200px!important;
}


/* Date Picker */

.datepicker {
    padding: 10px!important;
    border: 1px solid #e0e0e0!important;
    border-radius: 0!important;
    -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1)!important;
    -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1)!important;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1)!important;
}

.datepicker table tr td,
.datepicker table tr th, 
.datepicker table tr td span {
    border-radius: 0!important;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
    color: #ffffff;
    background-color: #7a6fbe;
    border: 0;
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active, 
.datepicker table tr td span.active.disabled:hover:active, 
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active, 
.datepicker table tr td span.active.disabled.active, 
.datepicker table tr td span.active.disabled:hover.active, 
.open .dropdown-toggle.datepicker table tr td span.active, 
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
    color: #ffffff;
    background-color: #7a6fbe;
    border: 0;
}


/* Colorpicker */

.colorpicker {
    padding: 10px!important;
}


/* Dropzone */

.dropzone {
    border: 1px solid #f1f1f1;
    border-radius: 0;
    background: #f9f9f9;
}


/* Image Cropper */

.img-preview {
    height: 150px;
    width: 220px;
    overflow: hidden;
    text-align: center;
}


/* Sparkline Charts */

.jqstooltip {
    box-sizing: content-box;
}


/* Flot Chart */

#flot1 {
    width: 100%;
    height: 250px;
}

#flot2 {
    width: 100%;
    height: 250px;
}

#flot3 {
    margin: 0 auto;
    width: 250px;
    height: 250px;
}

#flot3 .pie-chart-legend {
    padding: 5px;
}

#flot4 {
    width: 100%;
    height: 250px;
}

#flotTip {
    border-radius: 0;
    background: rgba(51,51,51,0.8);
    color: #fff;
    padding: 5px;
    font-size: 13px!important;
}


/* Vector Maps */

.jqvmap-zoomin,
.jqvmap-zoomout {
    background-color: #7a6fbe;
    color: #fff;
    border: 1px solid transparent;
    font-size: 13px;
    border-radius: 0;
    outline: none!important;
    padding: 3px 5px;
    height: auto;
    width: auto;
    margin-left: 7px;
}

.jqvmap-zoomin:hover,
.jqvmap-zoomin:focus,
.jqvmap-zoomin:active,
.jqvmap-zoomout:hover,
.jqvmap-zoomout:focus,
.jqvmap-zoomout:active {
    background-color: #6a5fac;
    border: 1px solid transparent;
}


/* Gritter Notifications */

.gritter-item {
    font-size: 13px;
    background: rgba(0,0,0, 0.7);
    background-image: none!important;
    padding: 15px;
}

.gritter-top {
	background: none!important;
}

.gritter-bottom {
	background: none!important;
}

.gritter-close {
	top: 15px;
    left: auto;
	right: 5px;
}

.gritter-image {
	width: 50px;
	height: 50px;
    border-radius: 100%;
}

.gritter-with-image {
    padding-left: 10px;
}

/* Calendar */


.fc button {
	height: auto;
    padding: 6px 12px;
	font-size: 13px;
}


.fc button::-moz-focus-inner { 
    margin: 0; 
    padding: 0; 
}
	
.fc-state-default {
	border: 1px solid;
}

.fc-state-default.fc-corner-left {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.fc-state-default.fc-corner-right {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.fc-state-default {
	background-color: #fff;
	background-image: none;
	border-color: #e5e5e5;
	color: #333;
	text-shadow: 0;
	box-shadow: 0;
    outline: 0!important;
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	color: #333333;
	background-color: #e6e6e6;
}

.fc-state-hover {
	color: #333333;
	text-decoration: none;
	background-position: 0 -15px;
	-webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}

.fc-state-down,
.fc-state-active {
    background-color: #f1f1f1;
    border: 1px solid #e5e5e5;
	box-shadow: 0!important;
}

.fc-state-disabled {
	cursor: default;
	background-image: none;
	opacity: 0.65;
	filter: alpha(opacity=65);
	box-shadow: none;
}

.fc-day-grid-event {
	padding: 5px;
    background: #22BAA0;
    border: 0;
}


/* List Groups */

.list-group-item.active {
    background: #f1f1f1;
    border-color: #DDDDDD;
    color: #333;
}

.list-group-item.active:hover {
    background: #e7e7e7;
    border-color: #DDDDDD;
    color: #333;
}


/* Header */

.navbar {
    border: none;
    background: #FFF;
    padding: 0px;
    height: 60px;
    margin: 0px;
    z-index: 999;
    border-radius: 0px;
    border: none;
    margin: auto;
    position: relative;
    -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
}

.page-header-fixed .navbar {
    position: fixed;
    right: 0;
    left: 0;
}

@media (min-width: 768px) {
    .page-content.container .navbar {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .page-content.container .navbar {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .page-content.container .navbar {
        width: 1170px;
    }
}

.topmenu-outer {
    height: 60px;
    background: #fff;
    margin-left: 160px;
}

.small-sidebar .topmenu-outer {
    margin-left: 65px;
}

.pace .pace-progress {
    background: #22BAA0;
}

.pace .pace-progress-inner {
    box-shadow: 0 0 10px #22BAA0, 0 0 5px #22BAA0;
}

.pace .pace-activity {
    border-top-color: #22BAA0;
    border-left-color: #22BAA0;
    margin-top: 60px;
}


/* logo */

.navbar .logo-box {
    position: relative;
    width: 160px;
    height: 60px;
    float: left;
    display: table;
}

.navbar .logo-box .logo-text {
    font-size: 20px;
    font-weight: bold;
    line-height: 60px;
    padding: 0 15px 0 15px;
    vertical-align: middle;
    display: table-cell;
    text-align: center;
}

.navbar .logo-box a:hover,
.navbar .logo-box a:focus {
    text-decoration: none;
}

.navbar .logo-box a {
    margin: 0 auto;
}

.small-sidebar .navbar .logo-box {
    width: 65px;
}


/* Top Menu */

.navbar-nav.navbar-right {
    margin-right: 0;
}

.top-menu .navbar-nav > li {
    height: 49px;
} 

.top-menu .navbar-nav > li > a {
    display: block;
    padding: 20px 18px;
    color: #5f5f5f;
    border: none;
    position: relative;
    line-height: 20px;
} 

.top-menu .nav > li > a:hover,
.top-menu .nav > li > a:focus,
.top-menu .nav > li > a:active,
.top-menu .nav .open > a,
.top-menu .nav .open > a:hover,
.top-menu .nav .open > a:focus {
    background: transparent;
} 

.top-menu .nav .open > a,
.top-menu .nav .open > a:hover,
.top-menu .nav .open > a:focus {
    background: transparent;
    border-color: none;
}

.top-menu .navbar-nav > li > a > i {
    font-size: 14px;
}

.top-menu .navbar-nav > li > a > .badge {
    font-family: "Open Sans", sans-serif;
    position: absolute;
    top: 12px;
    right: 7px;
    padding: 3px 6px;
    font-size: 11px;
    text-shadow: 0 1px 0 rgba(0,0,0,0.3);
}

.top-menu .navbar-nav > li > a > .user-name {
    padding: 0 10px 0 0;
    line-height: 20px;
}

.top-menu .navbar-nav > li > a > .user-name {
    padding: 0 10px 0 0;
    line-height: 20px;
}

@media (max-width: 991px) {
    .top-menu .navbar-nav > li > a > .user-name {
        display: none;
    }
}

.top-menu .navbar-nav > li > a > .user-name > i {
    margin-left: 5px;
}

.avatar {
    padding: 0px;
    margin: -19px 0 -15px 0;
    position: relative;
    display: inline-block;
}


/* Dropdowns */

.dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    float: left;
    list-style: none;
    text-shadow: none;
    padding: 0px;
    margin: 10px 0px 0px 0px;
    background-color: #fffff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid #d8dee4;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
}

.dropdown-menu li {
    font-size: 13px;
}

.dropdown-menu li a:hover {
    background: #f7f7f7;
}

.navbar-nav > li > .dropdown-menu {
    margin-top: 25px!important;
}

.dropdown-menu:before {
    position: absolute;
    top: -8px;
    left: 9px;
    right: auto;
    display: inline-block !important;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #d8dee4;
    border-left: 8px solid transparent;
    content: '';
}

.dropdown-menu:after {
    position: absolute;
    top: -7px;
    left: 10px;
    right: auto;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    border-left: 7px solid transparent;
    content: '';
}

.dropdown.dropup > .dropdown-menu:after,
.dropdown.dropup > .dropdown-menu:before,
.btn-group.dropup > .dropdown-menu:after,
.btn-group.dropup > .dropdown-menu:before,
.dropdown-toggle.dropup > .dropdown-menu:after,
.dropdown-toggle.dropup > .dropdown-menu:before {
    display: none !important;
}

.navbar-left .dropdown-menu:before,
.dropdown-menu.dropdown-menu-left:before {
    left: 16px;
    right: auto;
}

.navbar-left .dropdown-menu:after,
.dropdown-menu.dropdown-menu-left:after {
    left: 17px;
    right: auto;
}

.navbar-right .dropdown-menu:before,
.dropdown-menu.dropdown-menu-right:before {
    right: 16px;
    left: auto;
}

.navbar-right .dropdown-menu:after,
.dropdown-menu.dropdown-menu-right:after {
    right: 17px;
    left: auto;
}

.dropdown-menu.title-caret:after {
    border-bottom: 7px solid #fff;
}

.dropdown-menu > li.active > a:hover,
.dropdown-menu > li.active > a:focus,
.dropdown-menu > li.active > a:active,
.dropdown-menu > li.active > a,
.dropdown-menu > li.active > a:hover,
.dropdown-menu > li.active > a:focus {
    background: #F7F7F7;
    color: #333;
} 

.drop-title {
    display: block;
    border-bottom: 1px solid #F3F3F3;
    font-size: 14px;
    background: #fff;
    padding: 12px;
    color: #6a6a6a;
    margin: 0px;
}

.dropdown-menu li.drop-all a {
    padding: 10px;
    color: #707070;
    background: #FFF;
    font-size: 14px;
}

.dropdown-menu li.drop-all a:hover {
    background: #f7f7f7;
}

.dropdown-sm {
    width: 190px;
}

.dropdown-md {
    width: 230px;
}

.dropdown-lg {
    width: 280px;
}

.dropdown-menu li a {
    padding: 7px 10px 7px 10px;
    color: #5f5f5f;
    font-size: 13px;
}

.dropdown-menu li a i {
    margin-right: 10px;
}

.dropdown-menu li.no-link {
    padding: 7px 10px 7px 10px;
}

.dropdown-menu li.li-group {
    border-bottom: 1px solid #f1f1f1;
    padding: 5px 0px 5px 0px;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background-color: #eee;
    border-color: transparent;
}

.navbar .dropdown .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    margin-top: 40px!important;
}

.navbar .open .dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 25px!important;
}

.navbar .dropdown {
    display: inline-block;
}


/* Messages */

.messages {
    max-height: 300px;
}

.messages li a {
    color: #bababa;
    font-size: 13px;
    padding-left: 15px;
    text-decoration: none;
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
}

.messages li a:hover {
    background: #f7f7f7;
}

.messages li {
    border-bottom: 1px solid #f3f3f3;
}

.messages li a .msg-img {
    height: 100%;
    display: block;
    float: left;
    position: relative;
}

.online {
    position: absolute;
    top: 1px;
    right: 1px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #FFF;
    background: #ccc;
}

.online.on {
    background: #44AEA1;
}

.online.off {
    background: #f3565d;
}

.messages li a img {
    width: 40px;
}

.messages li a .msg-name {
    display: block;
    font-size: 14px;
    color: #707070;
    padding: 0 0 0 10px;
    margin: 0px;
    float: left;
}

.messages li a .msg-text {
    display: block;
    width: 210px;
    text-overflow: ellipsis;
    white-space: nowrap;   
    color: #a0a0a0;
    margin: 0px;
    float: left;
    padding: 0 0 0 10px;
}

.messages li a .msg-time {
    font-size: 11px;
    display: block;
    width: 210px;
    padding: 0 4px 0 8px;
    margin: 0px;
    float: right;
}


/* Tasks */

.tasks {
    max-height: 300px;
}

.tasks li a {
    color: #bababa;
    font-size: 13px;
    padding-left: 15px;
    text-decoration: none;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
}

.tasks li {
    border-bottom: 1px solid #f3f3f3;
}

.tasks li a:hover {
    background: #f7f7f7;
}

.task-icon.badge {
    height: 25px;
    width: 25px;
    display: block;
    float: left;
    position: relative;
    margin-right: 10px;
    padding-top: 6px;
}

.task-icon i {
    text-align: center;
    font-size: 12px;
}
    
.tasks li a .task-details {
    display: block;
    font-size: 13px;
    color: #868686;
    padding: 3px 0 0 0;
    margin: 0px;
    float: left;
    width: 148px;
}

.tasks li a .badge {
    margin: 0px;
    margin-right: 5px;
    max-width: 85px;
}


/* Page Content */

.page-content {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    background: #f7f8f8;
    min-height: 100%;
}

.page-content.container {
    padding-left: 0px;
    padding-right: 0px;
}


/* Sidebar */

.page-sidebar {
    width: 160px;
    display: block;
    z-index: 998;
    float: left;
    height: auto !important;
    overflow: visible !important;
}

.sidebar .sidebar-title {
    display: block;
    margin: 0 0 10px 0;
}

.page-sidebar-fixed:not(.page-header-fixed) .logo-box {
    position: fixed;
    display: block;
}

.page-sidebar-fixed:not(.page-header-fixed) .topmenu-outer {
    margin-left: 160px;
}

.small-sidebar.page-sidebar-fixed:not(.page-header-fixed) .topmenu-outer {
    margin-left: 65px;
}

.page-header-fixed .page-sidebar {
    padding-top: 60px;
}


.page-sidebar-fixed .sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    padding-top: 60px;
}

.page-sidebar-fixed .page-sidebar-inner {
    height: 100%;
}

.page-sidebar .page-sidebar-inner {
    height: 100%!important;
}

.small-sidebar .page-sidebar {
    width: 65px;
    height: 715px !important;
    position: fixed;
    &:hover{
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    }
    
}

.small-sidebar .page-sidebar-inner {
    overflow: visible!important;
}

.sidebar-header {
    overflow: hidden;
    clear: both;
    position: relative;
    padding: 20px;
}

.sidebar-profile-image {
    display: block;
}

.sidebar-profile {
    overflow: hidden;
    position: relative;
    display: block;
}

.sidebar-profile a {
    text-decoration: none;
}

.sidebar-profile img {
    width: 60px;
    display: block;
    margin: 0 auto;
}

.sidebar-profile span {
    color: #fff;
    font-weight: 600;
    display: block;
    padding: 8px 0 0 0;
    text-align: center;
}

.sidebar-profile span small {
    color: #90999c;
    padding: 5px 0;
}

.small-sidebar .sidebar-header,
.page-horizontal-bar .sidebar-header {
    display: none;
}


/* Horizontal Bar */

.horizontal-bar {
    width: 100%;
    display: block;
    z-index: 9998;
    float: none;
    height: auto!important;
    left: 0;
    right: 0;
    bottom: 0;
    height: 74px;
}


@media (min-width: 768px) {
    .page-sidebar-fixed.page-horizontal-bar .sidebar {
        position: fixed;
        top: 0;
        bottom: auto;
        padding-top: 60px;
    }
}

.small-sidebar .horizontal-bar .slimScrollDiv {
    overflow: visible!important;
}

.page-horizontal-bar.page-header-fixed .horizontal-bar {
    padding-top: 60px;
}

.page-horizontal-bar.page-sidebar-fixed .navbar {
    z-index: 9999;
}

@media (min-width: 768px) {
    .page-horizontal-bar.page-sidebar-fixed .page-content.container .horizontal-bar {
        width: 750px;
        margin: 0 auto;
    }
}

@media (min-width: 992px) {
    .page-horizontal-bar.page-sidebar-fixed .page-content.container .horizontal-bar {
        width: 970px;
        margin: 0 auto;
    }
}

@media (min-width: 1200px) {
    .page-horizontal-bar.page-sidebar-fixed .page-content.container .horizontal-bar {
        width: 1170px;
        margin: 0 auto;
    }
}

@media (max-width: 1200px) {
    .horizontal-bar .accordion-menu > li > a > p {
        display: none;
    }
    
    .compact-menu .horizontal-bar .accordion-menu > li > a > p {
        display: none!important;
    }
    
    body:not(.small-sidebar) .horizontal-bar .accordion-menu > li > a > .menu-icon {
        float: none!important;
        margin: 0!important;
    }
    
    .horizontal-bar .accordion-menu ul {
        top: 50px!important;
    }
}


.horizontal-bar .page-sidebar-inner {
    overflow: visible!important;
}

.horizontal-bar .accordion-menu {
    text-align: center;
	font-size: 0;
    width: 100%!important;
    overflow: visible!important;
}

.horizontal-bar .accordion-menu li {
    display: inline-block;
    margin: 0px !important;
    padding: 0px !important;
    position: relative;
}

.small-sidebar.page-horizontal-bar .horizontal-bar .accordion-menu > li > a,
.horizontal-bar .accordion-menu > li > a {
    padding: 16px!important;
}

.small-sidebar .horizontal-bar .accordion-menu li a span.menu-icon {
    font-size: 15px;
}

.horizontal-bar .accordion-menu > li > a > p {
    font-size: 13px;
}

.compact-menu .horizontal-bar .accordion-menu li a p {
    display: inline;
}

.horizontal-bar .accordion-menu .sub-menu li a {
    font-size: 13px;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.page-horizontal-bar.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li > a {
    width: auto;
}

.horizontal-bar .accordion-menu > li > a > span.arrow:before {
    display: none;
}

body:not(.small-sidebar) .horizontal-bar .menu.accordion-menu > li > a > .menu-icon {
    font-size: 14px;
}

.small-sidebar.page-horizontal-bar .menu.accordion-menu > li > a > span.menu-icon {
    margin: 0!important;
}

.horizontal-bar .accordion-menu > li > ul {
    left: 0!important;
    top: 74px;
    width: 200px;
    position: absolute;
}

.horizontal-bar .accordion-menu ul li {
    display: block;
    text-align: left;
}

.horizontal-bar .accordion-menu li.menu-title {
    display: none;
}

.small-sidebar .horizontal-bar .accordion-menu > li > a > p {
    display: none!important;
}

.small-sidebar .horizontal-bar .accordion-menu > li > ul {
    top: 50px;
}

.small-sidebar.page-horizontal-bar.page-sidebar-fixed .page-inner {
    padding-left: 0px;
}

.small-sidebar.page-sidebar-fixed.page-header-fixed.page-horizontal-bar .page-inner {
    padding: 110px 0 50px 0;
}

.small-sidebar.page-sidebar-fixed.page-header-fixed .page-inner {
	padding: 60px 0 50px 65px;
}

.small-sidebar.page-sidebar-fixed:not(.page-header-fixed) .page-inner {
	padding: 0 0 50px 65px;
}

.small-sidebar.page-header-fixed.page-horizontal-bar:not(.page-sidebar-fixed) .page-inner {
	padding: 0 0 50px 0;
}


/* Search */

.search-form {
    display: block;
    width: 100%;
    height: 60px;
    z-index: 99999;
    position: fixed;
    background: #fff;
    margin-top: -60px;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
}

.search-form .input-group {
    height: 60px;
}

.search-form .input-group input {
    color: #5f5f5f;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    background: none;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    padding: 0 20px!important;
}

.search-form .input-group button,
.search-form .input-group button:hover,
.search-form .input-group button:focus {
    background: none;
    box-shadow: none;
    border: none;
    outline: none;
    color: #5f5f5f;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
}


/* Menu */

.menu li a .badge {
    margin-left: 10px;
}

.menu.accordion-menu {
    padding: 0px;
    margin: 0px;
    width: 160px;
	color: white;
    position: relative;
    display: block;
}

.menu.accordion-menu a {
    display: block;
    padding: 15px;
    padding-left: 18px;
    padding-right: 18px;
    background: transparent;
    text-decoration: none;
    font-size: 13px;
}

.menu.accordion-menu > li > a {
    text-align: center;
}

body:not(.page-horizontal-bar):not(.small-sidebar) .menu.accordion-menu a {
    text-align: center;
}

.menu.accordion-menu a p {
    line-height: 1.42857143!important;
    margin: 0;
}

.menu.accordion-menu a span.menu-icon {
    display: inline-block;
	font-size: 20px;
    margin-bottom: 10px;
}

.menu.accordion-menu li > a > .arrow:before {
	width: 10px;
    float: right;
    display: block;
    margin-top: -20px;
    font-size: 16px;
    font-family: FontAwesome;
    height: auto;
    content: "\f104";
    font-weight: 300;
}

.menu.accordion-menu > li > a > .arrow:before {
    margin-top: -39px;
}

.menu.accordion-menu li.open > a > .arrow:before {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.menu.accordion-menu li {
	list-style-type: none;
}

.menu.accordion-menu li a .badge {
	float: right;
    margin-top: 1px;
    margin-right: 0px;
    margin-left: 0px;
    display: block;
}

.menu.accordion-menu ul {
    z-index: 9999;
}

.menu.accordion-menu ul li a {
    display: block;
    padding: 10px;
    background: transparent;
    text-decoration: none;
    font-size: 13px;
}

.menu.accordion-menu ul li ul {
    padding: 2px 0 2px 0;
}

.compact-menu .menu.accordion-menu .sub-menu li .sub-menu li a,
.hover-menu .menu.accordion-menu .sub-menu li .sub-menu li a,
.page-horizontal-bar .menu.accordion-menu .sub-menu li .sub-menu li a {
    padding-left: 26px;
}

.compact-menu .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li a,
.hover-menu .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li a,
.page-horizontal-bar .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li a {
    padding-left: 31px;
}

.compact-menu .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li .sub-menu li a,
.hover-menu .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li .sub-menu li a,
.page-horizontal-bar .menu.accordion-menu .sub-menu li .sub-menu li .sub-menu li .sub-menu li a {
    padding-left: 36px;
}

.menu ul {
    padding: 10px;
    z-index: 99;
}

.compact-menu:not(.small-sidebar) .menu.accordion-menu li a {
    text-align: left!important;
}

.compact-menu:not(.page-horizontal-bar) .menu.accordion-menu > li > a > p {
    display: inline!important;
}

.compact-menu .menu.accordion-menu a span.menu-icon {
    display: inline-block!important;
	font-size: 14px!important;
    margin-bottom: 0!important;
    margin-right: 10px!important;
}

.compact-menu .menu.accordion-menu li a .arrow:before {
    margin-top: -2px;
}

.page-horizontal-bar.compact-menu .menu.accordion-menu ul {
    top: 50px;
}

.page-horizontal-bar.compact-menu.small-sidebar .menu.accordion-menu ul {
    top: 46px!important;
}

.hover-menu:not(.page-horizontal-bar) .menu.accordion-menu > li > a > span.arrow {
    display: none;
}

.hover-menu .menu.accordion-menu > li:hover > ul,
.hover-menu .menu.accordion-menu > li.open:hover > ul {
    display: inline-block!important;
}

.hover-menu .accordion-menu li {
    position: relative;
}

.hover-menu .accordion-menu > li > ul {
    position: absolute;
    left: 160px;
    width: 200px;
}

.hover-menu:not(.page-horizontal-bar) .accordion-menu > li > ul {
    position: absolute;
    top: 0;
    left: 160px;
    width: 200px;
}

.hover-menu .sidebar,
.hover-menu .sidebar .page-sidebar-inner,
.hover-menu .sidebar .menu,
.hover-menu .sidebar .slimScrollDiv {
    overflow: visible!important;
}

.hover-menu .accordion-menu ul li a {
    text-align: left!important;
}

.compact-menu .accordion-menu li a p {
    display: inline;
}

.hover-menu .sidebar .slimScrollBar,
.hover-menu .slimScrollBar {
    display: none!important;
}


/* Small Menu */

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu {
    width: 65px;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .page-sidebar-inner {
    overflow: hidden!important;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover,
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu {
    width: 160px!important;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu > li > a {
    padding: 15px;
    text-align: center;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu > li > a {
    padding: 15px;
    padding-left: 18px;
    padding-right: 18px;
}

.compact-menu.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu > li > a {
    text-align: left;
}

.compact-menu.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu > li > a > .menu-icon {
    margin-right: 10px!important;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu ul li a {
    text-align: center;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li a .menu-icon {
    float: none;
    margin: 0!important;
    font-size: 14px;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li a .menu-icon {
    font-size: 20px;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li a p,
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li a .arrow, 
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li.menu-title,
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li a .badge {
    display: none!important;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li a p {
    margin: 0;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .menu.accordion-menu li.open .sub-menu {
    visibility: hidden;
    height: 0!important;
    padding: 0;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .sidebar-widget,
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .sidebar-header {
    display: block!important;
}
    
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li a p,
.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li.menu-title {
    display: block!important;
}

.compact-menu.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li a p,
.compact-menu.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li.menu-title {
    display: inline!important;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li.open .sub-menu  {
    visibility: visible;
    height: auto!important;
    padding: 10px;
}

.small-sidebar.page-sidebar-fixed:not(.page-horizontal-bar) .sidebar:hover .menu.accordion-menu li a .arrow {
    display: block!important;
    float: right;
}

.small-sidebar:not(.page-sidebar-fixed) .sidebar,
.small-sidebar:not(.page-sidebar-fixed) .sidebar > *,
.horizontal-bar,
.horizontal-bar > * {
    overflow: visible!important;
}

.small-sidebar:not(.page-sidebar-fixed) .sidebar .slimScrollBar,
.horizontal-bar .slimScrollBar {
    display: none!important;
}

.small-sidebar:not(.page-sidebar-fixed) .menu.accordion-menu {
    width: auto;
    oveflow: visible!important;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu,
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li {
    padding:0;
    margin:0;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li {
    list-style: none;
    position: relative;
    width: auto;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li a {
    text-decoration: none;
    display: block;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li > a {
    padding: 15px 25px 15px 25px;
    text-align: center;
    position: relative;
    width: 265px;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li a span.menu-icon {
    font-size: 14px;
    margin: 0 50px 0 0!important;
    float: left;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li a p {
    margin: 0;
    float: left;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li a .arrow:before {
	width: 10px;
    float: right;
    margin-left: 15px;
    margin-top: -2px;
    display: inline;
    font-size: 16px;
    font-family: FontAwesome;
    height: 18px;
    content: "\f104";
    font-weight: 300;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li:hover > a {
    z-index: 999;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li > ul {
    position: absolute;
    left: 65px;
    top: 44px;
    width: 200px;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu ul li a {
    display: block;
    padding: 10px!important;
    padding-left: 15px!important;
    padding-right: 15px!important;
    background: transparent;
    font-size: 13px;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu ul li span.menu-icon {
    margin-right: 15px;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li > a > span.arrow {
    display: none;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li a p {
    display: block;
    text-align: left;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu li.menu-title {
    display: none;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li:hover > ul,
.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li.open:hover > ul {
    display: inline-block!important;
}

.page-horizontal-bar .accordion-menu > li:hover > ul,
.page-horizontal-bar .accordion-menu > li.open:hover > ul {
    display: inline-block!important;
}

.small-sidebar:not(.page-sidebar-fixed) .accordion-menu > li.open > ul {
    display: none!important;
}

.page-horizontal-bar .accordion-menu > li.open > ul {
    display: none!important;
}


/* Page Inner */

.page-inner {
	padding: 0 0 50px 0;
    background: #F1F4F9;
    position: relative;
}

.page-header-fixed:not(.page-sidebar-fixed):not(.page-horizontal-bar) .page-inner {
	padding: 60px 0 50px 0;
}

.page-sidebar-fixed.page-header-fixed .page-inner {
	padding: 60px 0 50px 160px;
}

.page-horizontal-bar.page-sidebar-fixed.page-header-fixed .page-inner {
	padding: 134px 0 50px 0;
}

.page-horizontal-bar.page-sidebar-fixed.page-header-fixed.compact-menu .page-inner {
  padding: 111px 0 50px 0;
}

.page-sidebar-fixed:not(.page-header-fixed) .page-inner {
	padding: 0px 0 50px 160px;
}

.small-sidebar.page-sidebar-fixed.page-header-fixed .page-inner {
	padding: 60px 0 50px 65px;
}

.small-sidebar.page-sidebar-fixed:not(.page-header-fixed) .page-inner {
	padding: 0 0 50px 65px;
}

.small-sidebar.page-header-fixed:not(.page-sidebar-fixed) .page-inner {
	padding: 60px 0 50px 0;
}


/* Page Title */

.page-title {
    padding: 20px;
    background: #e9edf2;
    border-bottom: 1px solid #dee2e8;
}

.page-title h3 {
    font-size: 24px;
    font-weight: 300;
    color: #74767d;
    padding: 0px;
    margin: 0 0 4px 0;
}


/* Page Settings */

.page-breadcrumb .breadcrumb {
    float: left;
    background: transparent;
    padding: 0px;
    margin-top: 30px;
}

.page-breadcrumb ol.breadcrumb {
    margin: 0px;
}

.page-breadcrumb ol.breadcrumb li a {
    font-size: 13px;
    color: #90999c;
}

.page-breadcrumb ol.breadcrumb li.active {
    font-size: 13px;
    color: #90999c;
}

.page-breadcrumb ul {
    margin: 0px;
}

.page-breadcrumb ul li {
    float: left;
    display: inline;
}

.page-breadcrumb ul li a {
    font-size: 13px;
    color: #90999c;
}


/* Main Wrapper */

#main-wrapper {
    margin: 20px;
    padding: 0px;
}


/* Footer */

.page-footer {
    background: #fff;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 19px;
    padding-bottom: 19px;
}


/* Sidebar Pusher */

.sidebar-pusher {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9999;
    display: none;
}

.push-sidebar {
    display: inline-block!important;
    padding: 20px 18px;
    color: #5F5F5F;
    border: none;
    position: relative;
    line-height: 20px;
    float: left;
    font-size: 17px;
    text-decoration: none;
}

.push-sidebar:hover {
    color: #5F5F5F;
}

/* Search Button */

.search-button {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9999;
    display: none;
    right: 0;
}

.search-button a {
    display: inline-block!important;
    padding: 20px 18px;
    color: #5F5F5F;
    border: none;
    position: relative;
    line-height: 20px;
    float: right;
    font-size: 17px;
    text-decoration: none;
}


/* Icons */
/* FontAwesome */

.fontawesome-icon-list .fa-item {
    padding: 10px 20px 10px 20px;
    color: #333;
}

.fontawesome-icon-list .fa-item i {
    margin-right: 5px;
}

.fontawesome-icon-list .fa-item:hover {
    background: #f1f1f1;
}

.line-icons .line-icon-item {
    padding: 10px 20px 10px 20px;
    color: #333;
    display: block;
}

.line-icons .line-icon-item:hover {
    background: #f1f1f1;
}

.bs-glyphicons-list li span.glyphicon {
    margin: 5px 0 10px 0;
    float: none;
    font-size: 24px;
}

.bs-glyphicons-list li span {
    text-align: center;
    display: block;
}

.bs-glyphicons-list li {
    display: block;
    float: left;
    width: 25%;
    height: 115px;
    padding: 10px;
    font-size: 10px;
    line-height: 1.4;
    text-align: center;
    background-color: #f9f9f9;
    border: 1px solid #fff;
    list-style-type: none;
}

@media (min-width: 768px) {
    .bs-glyphicons-list li {
        width: 12.5%;
        font-size: 13px;
    }
}

@media (min-width: 768px) {
    .bs-glyphicons-list li {
        width: 12.5%;
        font-size: 13px;
    }
}


/* Calendar */

.calendar-event {
    padding: 7px 10px;
    color: #5B5B5B;
    background: #FCF8E3;
    margin-bottom: 5px;
    overflow: hidden;
}

.calendar-event p {
    float: left;
    margin: 0;
}

.calendar-event .remove-calendar-event {
    float: right;
    color: #5B5B5B;
}


/* Dashboard */

.info-box .progress {
    margin: 0;
}

.info-box .counter {
    z-index: 9;
    position: relative;
}
.info-box .info-box-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 0
}

.info-box .info-box-icon i {
    font-size: 31px;
    color: #B0B0B0;
}

.info-box .info-box-stats {
    float: left;
    margin-top: 5px;
}

.info-box .info-box-stats p {
    font-size: 18px;
    margin-bottom: 14px;
    color: #374051;
    font-weight: 300;
}

.info-box .info-box-stats span.info-box-title {
    display: block;
    font-size: 13px;
    margin-bottom: 10px;
    color: #B0B0B0;
    position: absolute !important;
    top: 68px !important;
}

.info-box .info-box-progress {
    clear: both;
}

@media (min-width: 768px) {
    .visitors-chart {
        border-right: 1px solid #eee;
    }
}

.stats-info ul {
    margin: 0;
}

.stats-info ul li {
    border-bottom: 1px solid #eee;
    padding: 12px 0;
}

.stats-info ul li:last-child {
    border-bottom: 0;
}

.stats-info ul li i {
    margin-left: 5px;
}

#flotchart1 {
    height: 340px;
    width: 100%;
}

#flotchart2 {
    height: 278px;
    width: 100%;
}

#flotchart3 {
    height: 200px;
    width: 100%;
}

.server-load > .server-stat {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 20px;
}

.server-load .server-stat span {
    font-size: 11px;
    margin-bottom: 3px;
}

.server-load .server-stat p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 3px;
}

.weather-current {
    overflow: hidden;
}

.weather-current p {
    font-size: 12px;
    margin: 5px 0 0 0;
    float: left;
    color: #8e8e8e;
}

.weather-current p span {
    font-size: 42px;
    clear: both;
    margin-top: 15px;
    display: block;
    color: #4E5E6A;
    font-weight: 300;
}

.weather-current p span sup {
    font-size: 25px;
}

.weather-current .weather-icon {
    font-size: 40px;
    display: block;
    float: left;
    margin: 15px 15px 0 0;
}

.weather-day {
    margin: 0;
    font-weight: 600;
    text-align: right;
    font-size: 22px;
}

.weather-day small {
    font-size: 11px;
    color: #8e8e8e;
    margin: 0;
}

.weather-info {
    width: 100%;
    display: block;
    overflow: hidden;
}

.weather-info li {
    padding: 8px 0;
}

.weather-top {
    border-bottom: 1px solid #f1f1f1;
    overflow: hidden;
    padding: 0 0 15px 0;
    margin: 0 0 15px 0;
}

.weather-days {
    margin: 15px 0 0 0 ;
    padding: 15px 0 0 0;
    border-top: 1px solid #f1f1f1;
}

.weather-days li span {
    display: block;
    text-align: center;
}

.weather-days li i {
    display: block;
    font-size: 22px;
    text-align: center;
    margin: 10px 0 10px 0;
}

@media (max-width: 768px) {
    .weather-days li {
        margin-bottom: 15px;
    }
}

.live-tile {
    width: 100%;
    margin: 0;
    height: 155.5px;
}

.twitter-box {
    background: #5EA9DD;
}

.facebook-box {
    background: #3B5998;
}

.twitter-box i,
.facebook-box i {
    position: absolute;
    right: 10px;
    bottom: 0;
    font-size: 50px;
    color: rgba(255,255,255,0.3);
}

.tile-date {
    display: block;
    margin-top: 15px;
    color: rgba(255,255,255,0.7);
}

.inbox-widget {
    height: 341px!important;
}

.inbox-widget a {
    display: block;
    overflow: hidden;
    text-decoration: none;
}

.inbox-widget .inbox-item {
    padding: 10px 0;
    border-bottom: 1px solid #f1f1f1;
    overflow: hidden;
    position: relative;
}

.inbox-widget a:last-child >.inbox-item {
    border-bottom: 0;
}

.inbox-widget .inbox-item .inbox-item-img {
    float: left;
    width: 40px;
    display: block;
    margin-right: 15px;
}

.inbox-widget .inbox-item img {
    width: 40px;
}

.inbox-widget .inbox-item .inbox-item-author {
    color: #333;
    font-weight: 600;
    display: block;
    margin: 0 0 0 0;
}

.inbox-widget .inbox-item .inbox-item-text {
    color: #a0a0a0;
    display: block;
    margin: 0;
    font-size: 11px;
}

.inbox-widget .inbox-item .inbox-item-date {
    position: absolute; 
    top: 2px;
    right: 7px;
    color: #a9a9a9;
    font-size: 11px;
}

#rickshaw-chart {
    margin: 0 -22px -25px -20px;
    height: 100px;
}

.project-stats .progress {
    margin: 3px 0 0 0;
}


/* Profile */

.profile-cover {
    background: url('../images/profile-cover.png');
    background-size: cover;
    width: 100%;
    height: 300px;
    position: relative;
}

.profile-image {
    position: absolute;
    margin: 200px 20px 20px 20px;
}

.profile-image img {
    display: block;
    margin: 0 auto;
    width: 150px;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 5px #fff;
    -moz-box-shadow: 0 0 0 5px #fff;
    -o-box-shadow: 0 0 0 5px #fff;
    box-shadow: 0 0 0 5px #fff;
}

.profile-info {
    position: absolute;
    bottom: 10px;
    right: 20px;
    padding: 0;
    margin: 0;
    text-align: right;
}

.profile-info-value {
    color: #fff;
    display: inline-block;
    margin-left: 40px;
}

.user-profile {
    margin-top: 60px;
}

.team .team-member {
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
    float: left;
    position: relative;
}

.team .team-member .online {
    top: 5px;
    right: 5px;
}

.team .team-member img {
    width: 50px;
    float: left;
    border-radius: 50%;
    margin: 0 5px 0 5px;
}

.team .more-members a {
    display: block;
    text-align: center;
}

.profile-timeline ul li .timeline-item-header {
    width: 100%;
    overflow: hidden;
}

.profile-timeline ul li .timeline-item-header img {
    width: 50px;
    float: left;
    margin-right: 10px;
    border-radius: 50%;
}

.profile-timeline ul li .timeline-item-header p {
    margin: 0;
    color: #000;
    font-weight: bold;
}

.profile-timeline ul li .timeline-item-header p span {
    margin: 0;
    color: #8E8E8E;
    font-weight: normal;
}

.profile-timeline ul li .timeline-item-header small {
    margin: 0;
    color: #8E8E8E;
}

.profile-timeline ul li .timeline-item-post {
    padding: 20px 0 0 0;
    position: relative;
}

.profile-timeline ul li .timeline-item-post > img {
    width: 100%;
}

.timeline-options {
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #f1f1f1;
    padding: 10px 0 10px 0;
}

.timeline-options a {
    display: block;
    margin-right: 20px;
    float: left;
    color: #333;
    text-decoration: none;
}

.timeline-options a i {
    margin-right: 3px;
}

.timeline-options a:hover {
    color: #22BAA0;
}

.timeline-comment {
    overflow: hidden;
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 5px;
}

.timeline-comment .timeline-comment-header {
    overflow: hidden;
}

.timeline-comment .timeline-comment-header img {
    width: 30px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
}

.timeline-comment .timeline-comment-header p {
    color: #000;
    float: left;
    margin: 0;
    font-weight: bold;
}

.timeline-comment .timeline-comment-header small {
    font-weight: normal;
    color: #8E8E8E;
}

.timeline-comment p.timeline-comment-text {
    display: block;
    color: #333;
    font-size: 12px;
    padding-left: 40px;
}

.post-options {
    overflow: hidden;
    margin-top: 15px;
    margin-left: 15px;
}

.post-options a {
    display: block;
    margin-top: 5px;
    margin-right: 20px;
    float: left;
    color: #333;
    text-decoration: none;
    font-size: 17px;
}

.post-options a:hover {
    color: #22BAA0;
}

#gallery .mix {
	display: none;
}


/* Mailbox */

.mailbox-nav li a {
    display: block;
    padding: 10px 7px;
    color: #999;
    text-decoration: none;
    border-bottom: 1px solid #E7EAEC;
    overflow: hidden;
}

.mailbox-nav li a:hover,
.mailbox-nav .active a { 
    color: #333;
}

.mailbox-nav li a i {
    margin-right: 5px;
}

.mailbox-content {
    background: #fff;
    padding: 15px;
}

.mailbox-header {
    margin-bottom: 20px;
}

.mailbox-header h2 {
    margin: 3px 0;
}

.mailbox-content {
    overflow: hidden;
}

.mailbox-content table thead tr th {
    border-bottom: 1px solid #ddd;
}

.mailbox-content table tbody tr td {
    cursor: pointer;
}

.mailbox-content table tbody tr:hover {
    background: #FAFAFA; 
}

.mailbox-content table tbody tr.read {
    background: #F9F9F9;
    color: #7F7F7F;
}

.mailbox-content table tbody tr.checked {
    background: #FFFFCC;
}

.mail-hidden-options {
    display: none;
}

.message-header {
    overflow: hidden;
    border-bottom: 1px solid #f1f1f1;
    padding: 5px 0 15px 0;
    margin-bottom: 10px;
}

.message-header h3 {
    float: left;
    font-size: 18px;
    margin: 0;
    color: #666;
}

.message-header p {
    float: right;
    margin: 0;
}

.message-sender {
    overflow: hidden;
    border-bottom: 1px solid #f1f1f1;
    padding: 0 0 5px 0;
    margin-bottom: 10px;
}

.message-sender img {
    width: 30px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.message-sender p {
    display: inline-block;
}

.message-sender span {
    color: #7F7F7F;
}

.message-content {
    overflow: hidden;
    border-bottom: 1px solid #f1f1f1;
    padding: 0 0 5px 0;
    margin-bottom: 10px;
}

.message-attachments {
    overflow: hidden;
    border-bottom: 1px solid #f1f1f1;
    padding: 0 0 15px 0;
    margin-bottom: 10px;
}

.message-attachment {
    position: relative;
    width: 200px;
    float: left;
    margin-right: 10px;
    border: 1px solid #f1f1f1;
}

.message-attachment a {
    text-decoration: none;
}

.message-attachment img {
    display: block;
    width: 100%;
}

.attachment-info {
    background: #f9f9f9;
    padding: 10px;
}

.attachment-info p {
    margin: 0;
}

.attachment-info span {
    font-size: 11px;
    color: #aaa;
}

.compose-message {
    overflow: hidden;
    padding: 0 0 10px 0;
}

.compose-options {
    width: 100%;
    overflow: hidden;
    display: block;
}


/* Notifications */

.box-notifications {
    max-height: 312px;
    overflow-y: scroll;
}

.box-notifications ul li {
    display: block;
    padding: 11px 0 11px 0;
    border-bottom: 1px solid #f1f1f1;
}

.box-notifications ul li:first-child {
    padding: 0 0 11px 0;
}

.box-notifications ul li:last-child {
    padding: 11px 0 0 0;
    border-bottom: 0;
}

.box-notifications ul li p {
    margin: 0;
}


/* Extra */

.page-error #main-wrapper {
    margin: 5%;
}

.page-error .details {
    margin-bottom: 20px;
    text-align: center;
} 

.coming-soon .page-inner {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../images/coming-soon.jpg');
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../images/coming-soon.jpg');
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../images/coming-soon.jpg');
    background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../images/coming-soon.jpg');
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url('../images/coming-soon.jpg');
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../images/coming-soon.jpg');
    background-size: cover;
}


/* Login */

// .page-login #main-wrapper,
// .page-register #main-wrapper,
// .page-forgot #main-wrapper,
// .page-lock-screen #main-wrapper {
//     margin: 5%;
// }

.login-box {
    margin: auto;
    max-width: 400px;
}

.login-box .logo-name {
    display: block;
    font-size: 22px;
    font-weight: bold;
    text-decoration: none;
    color: #6c6c6c;
}

.login-box .user-box {
    display: block;
    clear: both;
    overflow: hidden;
}

.login-box .user-box img {
    width: 100px;
    display: block;
    margin: 0 auto;
}

.login-box .user-box form {
    overflow: hidden;
}


/* Search Results */

.search-item {
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid #f1f1f1;
}

.search-item p {
    margin: 0;
}

.search-item a {
    text-decoration: none;
}

.search-item a.search-link {
    color: #22BAA0;
}


/* Chat */

.chat {
    position: relative;
    padding-bottom: 100px;
}

.chat .chat-item {
    display: block;
    padding: 10px 15px;
    width: 100%;
    overflow: hidden;
}

.chat .chat-item-right .chat-message {
    float: right;
}

.chat .chat-item .chat-image {
    float: left;
    display: inline-block;
}

.chat-item-left .chat-message {
    margin-top: 5px;
}

.chat .chat-item .chat-message {
    position: relative;
    border-radius: 12px;
    padding: 4px 12px;
    max-width: 75%;
    background: #f1f1f1;
    display: inline-block;
}

.chat .chat-item img {
    display: block;
    width: 40px;
    border-radius: 50%;
    margin-right: 5px;
}

.chat .chat-item-right .chat-message {
    background: #55BAA0;
    float: right;
    color: #fff;
}

.chat-write {
    padding: 5px;
    background: #fff;
    position: absolute;
    bottom: 0;
    width: 240px;
}

.chat-write input {
    width: 100%;
}


/* Transitions */

.top-menu .navbar-nav > li > a > .badge,
.menu li a .arrow:before,
.menu li.open > a > .arrow:before {
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}


/* Overflow */

.navbar .logo-box,
.navbar .logo-box a,
.top-menu .navbar-nav > li > a > img,
.info,
.messages li a,
.messages li a .msg-img,
.messages li a .msg-text,
.messages li a .msg-time,
.tasks li a,
.tasks li a .task-icon,
.search-form,
body:not(.small-sidebar):not(.page-horizontal-bar) .menu.accordion-menu,
.page-inner,
.small-sidebar .page-sidebar-fixed .page-inner,
.page-breadcrumb,
.page-breadcrumb .breadcrumb,
.page-breadcrumb ul,
.info-box,
.text-box,
.small-sidebar .accordion-menu li a,
.page-footer,
.color-switcher {
    overflow: hidden;
}


/* Settings */

.color-switcher {
    display: block;
    width: 100%;
    margin: 10px 0 5px 0;
}

.color-switcher .colorbox {
    dipslay: block;
    width: 28px;
    height: 28px;
    margin: 0 5px 0 0;
    padding: 0px;
    border: 2px solid #f1f1f1;
    cursor: pointer;
    float: left;
    border-radius: 50%;
}

.color-switcher .colorbox:hover {
    border: 2px solid #dedede;
}


/* Blue */
.color-switcher .colorbox.color-blue { 
    background-color: #12afcb;
}


/* Red */
.color-switcher .colorbox.color-red { 
    background-color: #F25656;
}


/* Green  */
.color-switcher .colorbox.color-green { 
    background-color: #22baa0;
}


/* Purple  */
.color-switcher .colorbox.color-purple { 
    background-color: #7a6fbe;
}


/* Black  */
.color-switcher .colorbox.color-dark { 
    background-color: #34425A;
}


/* White  */
.color-switcher .colorbox.color-white { 
    background-color: #fff;
}


/* Media Queries */

@media (max-width: 991px) {
    .profile-image {
        right: 0;
        left: 0;
    }
    
    .profile-info {
        top: 10px;
        right: 0;
        left: 0;
        bottom: auto;
        text-align: center;
    }

    .profile-info-value {
        color: #fff;
        float: none;
        margin: 0 10px 0 10px;
        display: inline-block;
    }
    
    .mailbox-header h2 {
        margin: 20px 0;
        text-align: center;
    }
}

@media (max-width: 767px) {
    
    .sidebar {
        opacity: 0;
        visibility: hidden;
        display: none;
        width: 0;
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
    }
    
    body:not(.page-header-fixed) .sidebar {
        padding-top: 0!important;
    }
    
    .small-sidebar .sidebar.visible,
    .page-horizontal-bar .sidebar.visible {
        margin-right: -65px;
        opacity: 1;
        visibility: visible;
        width: 65px;
        display: block;
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
    }
    
    body:not(.small-sidebar) .sidebar.visible {
        margin-right: -160px;
        opacity: 1;
        visibility: visible;
        display: block;
        width: 160px;
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
    }
    
    .small-sidebar .sidebar,
    .horizontal-bar {
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
    }
    
    .navbar .logo-box {
        margin: 0 auto;
        background: transparent!important;
        text-align: center;
        width: 100%!important;
        border: 0!important;
    }
    
    .navbar .logo-box a {
        color: #5F5F5F!important;
    }
    
    .navbar .logo-box .logo-text {
        width: 100%;
        display: inline-block!important;
    }
    
    .page-sidebar-fixed:not(.page-header-fixed) .logo-box {
        position: relative;
        display: block;
    }
    
    .small-sidebar .navbar .logo-box a span {
        display: inline-block;
    }
    
    .sidebar-pusher {
        display: block;
    }
    
    .search-button {
        display: block;
    }
    
    .topmenu-outer,
    .small-sidebar .topmenu-outer,
    .page-sidebar-fixed:not(.page-header-fixed) .topmenu-outer {
        margin: 0;
    }
    
    .top-menu {
        display: none;
    }
    
    .page-inner {
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
    }
    
    .page-inner.sidebar-visible {
        -webkit-transform: translate3d(160px, 0, 0);
        transform: translate3d(160px, 0, 0);
    }
    
    .small-sidebar .page-inner.sidebar-visible,
    .page-horizontal-bar .page-inner.sidebar-visible {
        -webkit-transform: translate3d(65px, 0, 0);
        transform: translate3d(65px, 0, 0);
    }
    
    .page-sidebar-fixed.page-header-fixed .page-inner {
        padding: 60px 0 50px 0!important;
    }
    
    .horizontal-bar {
        float: left;
        height: auto!important;
        display: block;
        z-index: 1029;
        overflow: visible!important;
    }
    
    .small-sidebar .horizontal-bar {
        height: auto!important;
    }
    
    .horizontal-bar .menu li {
        float: none;
        display: block;
    }
    
    .horizontal-bar .accordion-menu li > ul,
    .small-sidebar .horizontal-bar .accordion-menu li > ul {
        left: 65px!important;
        top: 0;
    }
    
    .small-sidebar.page-horizontal-bar .accordion-menu > li > a,
    .small-sidebar.page-horizontal-bar:not(.page-sidebar-fixed) .accordion-menu > li > a {
        padding: 15px!important;
        text-align: center;
        width: 65px;
    }
    
    .horizontal-bar .menu li a p {
        display: none;
    }
    
    .small-sidebar.page-horizontal-bar .horizontal-bar .accordion-menu > li > a > .menu-icon {
        margin-right: 0!important;
        font-size: 16px;
        float: none!important;
    }
    
    .horizontal-bar .accordion-menu > li > a {
        padding: 15px;
        text-align: center;
        width: 65px;
    }
    
    .page-horizontal-bar .page-inner {
        padding: 60px 0 50px 0;
    }
    
    .page-horizontal-bar.page-sidebar-fixed.page-header-fixed .page-inner {
        padding: 60px 0 50px 0;
    }
    
    .small-sidebar.page-sidebar-fixed.page-header-fixed.page-horizontal-bar .page-inner {
        padding: 60px 0 50px 0;
    }
    
    .small-sidebar.page-header-fixed.page-horizontal-bar:not(.page-sidebar-fixed) .page-inner {
        padding: 60px 0 50px 0;
    }
    
    body:not(.page-header-fixed) .page-inner {
        padding: 0 0 50px 0;
    }    
}

.overlay {
    background: rgba(0,0,0,0.4);
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
}

.show-menu .overlay {
    display: block;
}

._720kb-datepicker-calendar-day._720kb-datepicker-today {
  background:red;
  color:white;
}
 /* analyses */
.scrollup{
    height: 50px;
    width: 50px;
    background-color: #3f964a;
    position: fixed;
    bottom: 25px;
    right: 40px;
    border-radius: 50%;
    cursor:pointer;
}
.scrollupicon{
    position: absolute;
    top: 13px;
    left: 12px;
    color: #fff;
    font-size: 25px;
}

/* checkbox credit client */

.material-switch{
    width: 146px !important;
    margin-top: 6px !important;
}
.spanforcredit{
    /* this my spanforcredit */
    position: relative;
    left: 43px;
    font-size: 15px;
}
.material-switch > input[type="checkbox"] {
    display: none;   
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative; 
    /*width: 40px;*/ 
}

.material-switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.material-switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}

 /*   position client map */

.pinupdated{
    background: #e6154f!important;
}

.pinnumber{
    /* Safari */
-webkit-transform: rotate(45deg);
/* Firefox */
-moz-transform: rotate(45deg);
/* IE */
-ms-transform: rotate(45deg);
/* Opera */
-o-transform: rotate(45deg);
/* Internet Explorer */
//filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
/* normal style*/
    position: absolute;
    color: #fff;
    left: 9px;
    z-index: 999999999;
    top: 7px;
}


/**
 *  table objectifs
 */

 .objectif{
    background-color : #e3e6e0;
 }

 .prime{
    background-color : #c3cfd8;
 }

input[required]{
    background-color: #dedcdc !important;
}
input[required=""]{
    background-color: #dedcdc !important;
}
input[required="required"]{
    background-color: #dedcdc !important;
}

select[required]{
    background-color: #dedcdc !important;
}
select[required=""]{
    background-color: #dedcdc !important;
}
select[required="required"]{
    background-color: #dedcdc !important;
}

textarea[required="required"]{
    background-color: #dedcdc !important;
}
textarea[required=""]{
    background-color: #dedcdc !important;
}
textarea[required]{
    background-color: #dedcdc !important;
}

div[required]{
    background-color: #dedcdc !important;
}
div[required=""]{
    background-color: #dedcdc !important;
}
div[required="required"]{
    background-color: #dedcdc !important;
}

select.green_bg{background: rgb(36, 196, 63)!important;}
input.green_bg{background: rgb(36, 196, 63)!important;}

.flex-content-objectif {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.flex-content-objectif>div:first-child {
    width: 130px;
}

.flex-content-objectif>div:not(:first-child) {
    width: 80px;
}
.flex-content-objectif>div {
    margin-bottom: 2px;
}
.green_bg{
    background: rgb(36, 196, 63)!important;
}
.w-80{
    width: 80px;
}
.bold{ font-weight: bold;}
/**
routings ->  route ->table
*/

.routes > tr > td{
    padding: 0px !important;
    border-left:0px!important;
    border-right:0px!important;
}


.posmarker{
    height: 12px;
    width: 12px;
    border-radius: 50%;
}

.reduirbtn{
    width: 20px;
    height: 20px;
}
.reduiricon{
        font-size: 9px;
}

.amcharts-chart-div{
    a{
        display: none!important;
    }
} 

/**
 * client table index
 */
 .client_table_index{
    padding:0px!important;
 }

 .sp_actif{
    height: 10px;
    width: 10px;
    background-color: #46c778;
    border-radius: 100px;
 }
 .sp_not_actif{
    height: 10px;
    width: 10px;
    background-color: #ef5b5b;
    border-radius: 100px;
 }
/*
 #getSumByActivite > .amcharts-main-div{
        margin-left: -60px;
 }
 #getSumByCategory > .amcharts-main-div{
        margin-left: -101px;
 }
*/
 .progress-bar-vertical {
  width: 32px;
   min-height: 474px;
    margin-bottom: 0px;
  display: flex;
  align-items: flex-end;
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}

#progressbar_clients
{
    display: flex;
    justify-content: space-between;
}
.value_taux_pr{
    position: inherit!important;
}

.rootingscommand th {
     border: 0px !important;
     padding-left : 10px;
     padding-right: 10px;
}
.rootingscommand td {
     border: 0px !important;
     padding-left : 10px;
     padding-right: 10px;
}
.rootingscommand td.client {
     border: 1px !important;
     padding-left : 1px;
     padding-right: 1px;
     padding-top:2px;
}

.carrered,
.carreyellow,
.carregreen,
.carreblue,
.carreblueciel{
    cursor: pointer;
}

.carrered{
    height: 11px;
    width: 11px;
    background-color: red;
}
.carreyellow{
    height: 11px;
    width: 11px;
    background-color: yellow;
}
.carregreen{
    height: 11px;
    width: 11px;
    background-color: green;
}
.carreblue{
    height: 11px;
    width: 11px;
    background-color: blue;
}
.carreblueciel {
    height: 11px;
    width: 11px;
    background-color: #4cd4f1;
}

@media print
{
    .noprint {display:none;}
    .imprime-center{text-align: center!important;}
    .page-inner{
        page-break-after: always;
    }
}

@media screen
{

}
@page {
    size: auto;   /* auto is the initial value */
    margin-bottom: 0mm;  /* this affects the margin in the printer settings */
}

.amcharts-chart-div > svg{height: 100%!important;}

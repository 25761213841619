#menu-analyses>li {
    margin-left: 20px;
    margin-bottom: 20px;
}

#menu-analyses>li>a {
    width: 283px;
    height: 72px;
    padding: 0;
    border-radius: 14px!important;
    position: relative;
    border: 1px solid #d9d9d9;
    -webkit-box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4);
    box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4);
}

#menu-analyses>li>a>div:first-child {
    /* width: 31px; */
    height: 70px;
    /* float: left; */
    position: absolute;
    top: 10px;
    left: 5px;
    font-size: 36px;
    color: #fff;
}

#menu-analyses>li>a>div:nth-child(2) {
    background-image: url(../images/bg-analyse-menu1.png);
    background-repeat: no-repeat;
    width: 241px;
    height: 70px;
    float: right;
    line-height: 70px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 13px;
    div {
        margin-left: 30px;
        font-size: 12px;
        color: #337ab7;
        font-weight: bold;
        width: 170px;
        float: left;
    }
    i {
        font-size: 28px;
        line-height: 70px;
    }
}

.analyse-goback {
    font-size: 36px;
    color: #22baa0;
    border: 5px solid;
    border-radius: 43px;
    width: 47px;
    padding-left: 2px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: -77px;
}
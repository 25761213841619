.block_list_cl {
  border: 1px solid #ddd;
  height: 150px;
  background-image: linear-gradient(white, #f5f1ed);
  box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4);
  margin-bottom: 12px;
}

.bloqued_client {
  color: white;
  background: repeating-linear-gradient(45deg, #fbe3e4, #fbe3e4 20px, #ffced9 20px, #ffced9 40px);
}

.btn_load_more{
    background: #ececec;
    padding: 10px 0px 10px 0px;
    font-size: 17px;
    border: 1px solid #ddd;
    box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    font-weight: 500;
}

.credit_positive{
  color: blue;
}
.credit_negative{
  color: red;
}
.m-t-0{
  margin-top: 0px!important;
}

.m-b-0{
  margin-bottom: 0px!important;
}

.container-info-cl{
  display: flex;
  justify-content: space-around;;
}
.container-info-cl>div{
  width: 20%;
  text-align: center;
}
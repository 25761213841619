.bg-white {
    background: white;
}

.pos-rlt {
    position: relative;
}

.pos-stc {
    position: static !important;
}

.pos-abt {
    position: absolute;
}

.pos-fix {
    position: fixed;
}

.pos-r0 {
    right: 0;
}

.show {
    visibility: visible;
}

.pointer {
    cursor: pointer;
}

.no-pointer {
    cursor: default;
}

.line {
    width: 100%;
    height: 2px;
    margin: 10px 0;
    font-size: 0;
    overflow: hidden;
}

.line-xs {
    margin: 0
}

.line-lg {
    margin-top: 15px;
    margin-bottom: 15px
}

.line-dashed {
    border-style: dashed !important;
    background-color: transparent;
    border-width: 0;
}

.no-line {
    border-width: 0
}

.no-border,
.no-borders {
    border-color: transparent;
    border-width: 0
}

.no-radius {
    border-radius: 0
}

.block {
    display: block;
}

.block.hide {
    display: none;
}

.inline {
    display: inline-block !important;
}

.none {
    display: none;
}

.pull-none {
    float: none;
}

.rounded {
    border-radius: 500px;
}

.clear {
    display: block;
    overflow: hidden;
}

.no-bg {
    background-color: transparent;
    color: inherit;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.l-h-0x {
    line-height: 0;
}

.l-h-1x {
    line-height: 1.2;
}

.l-h-2x {
    line-height: 2em;
}

.l-s-1x {
    letter-spacing: 1
}

.l-s-2x {
    letter-spacing: 2
}

.l-s-3x {
    letter-spacing: 3
}

.font-normal {
    font-weight: normal;
}

.font-thin {
    font-weight: 300;
}

.font-bold {
    font-weight: 700;
}
.text-white{
    color: white;
}
.text-3x {
    font-size: 3em;
}

.text-2x {
    font-size: 2em;
}

.text-sm {
    font-size: 14px;
}

.text-xs {
    font-size: 13px;
}

.text-xxs {
    text-indent: -9999px
}

.text-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-cap:first-letter {
    text-transform: uppercase;
}

.text-u-c {
    text-transform: uppercase;
}

.text-l-t {
    text-decoration: line-through;
}

.text-u-l {
    text-decoration: underline;
}

.text-active,
.active > .text,
.active > .auto .text {
    display: none !important;
}

.active > .text-active,
.active > .auto .text-active {
    display: inline-block !important;
}

.box-shadow {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}

.box-shadow-lg {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}

.box-shadow-inset-lg {
    box-shadow: inset 0px 16px 15px -15px rgba(0, 0, 0, 0.2);
}

.text-shadow {
    font-size: 170px;
    text-shadow: 0 1px 0 #ccc, 0 2px 0 lighten(#ccc, 10%), 0 5px 10px rgba(0, 0, 0, .125), 0 10px 20px rgba(0, 0, 0, .2);
}

.no-shadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.wrapper-xs {
    padding: 5px;
}

.wrapper-sm {
    padding: 10px;
}

.wrapper {
    padding: 15px;
}

.wrapper-md {
    padding: 20px;
}

.wrapper-lg {
    padding: 30px;
}

.wrapper-xl {
    padding: 50px;
}

.padder-lg {
    padding-left: 30px;
    padding-right: 30px
}

.padder-md {
    padding-left: 20px;
    padding-right: 20px
}

.padder {
    padding-left: 15px;
    padding-right: 15px
}

.padder-v {
    padding-top: 15px;
    padding-bottom: 15px
}

.padder-v-md {
    padding-top: 20px;
    padding-bottom: 20px
}

.padder-v-lg {
    padding-top: 30px;
    padding-bottom: 30px
}

.padder-v-xl {
    padding-top: 50px;
    padding-bottom: 50px
}

.no-padder {
    padding: 0 !important;
}

.p-l-n {
    padding-left: 0;
}

.pull-in {
    margin-left: -15px;
    margin-right: -15px;
}

.pull-out {
    margin: -10px -15px;
}

.b {
    border: 1px solid rgba(0, 0, 0, 0.05)
}
 

.b-light {
    border-color: #eee
}
 

.b-black {
    border-color: #000
}
 
 

.b-white {
    border-color: #fff
}

.b-dashed {
    border-style: dashed !important;
}

// .b-l-light{border-left-color: @brand-light}
// .b-l-dark{border-left-color: @brand-dark}
// .b-l-black{border-left-color: @brand-dark}
// .b-l-primary{border-left-color: @brand-primary}
// .b-l-success{border-left-color: @brand-success}
// .b-l-info{border-left-color: @brand-info}
// .b-l-warning{border-left-color: @brand-warning}
// .b-l-danger{border-left-color: @brand-danger}
.b-l-white {
    border-left-color: #fff
}

.b-l-2x {
    border-left-width: 2px
}

.b-l-3x {
    border-left-width: 3px
}

.b-l-4x {
    border-left-width: 4px
}

.b-l-5x {
    border-left-width: 5px
}

.b-2x {
    border-width: 2px
}

.b-3x {
    border-width: 3px
}

.b-4x {
    border-width: 4px
}

.b-5x {
    border-width: 5px
}

.m-xxs {
    margin: 2px 4px
}

.m-xs {
    margin: 5px;
}

.m-sm {
    margin: 10px;
}

.m {
    margin: 15px;
}

.m-md {
    margin: 20px;
}

.m-lg {
    margin: 30px;
}

.m-xl {
    margin: 50px;
}

.m-n {
    margin: 0 !important
}

.m-l-none {
    margin-left: 0 !important
}

.m-l-xs {
    margin-left: 5px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-l {
    margin-left: 15px
}

.m-l-md {
    margin-left: 20px;
}

.m-l-lg {
    margin-left: 30px;
}

.m-l-xl {
    margin-left: 40px;
}

.m-l-xxl {
    margin-left: 50px;
}

.m-l-n-xxs {
    margin-left: -1px
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}

.block-with-text {
    @include multiLineEllipsis($lineHeight: 1.5em, $lineCount: 3, $bgColor: white);
}

.m-l-n-xs {
    margin-left: -5px
}

.m-l-n-sm {
    margin-left: -10px
}

.m-l-n {
    margin-left: -15px
}

.m-l-n-md {
    margin-left: -20px
}

.m-l-n-lg {
    margin-left: -30px
}

.m-l-n-xl {
    margin-left: -40px
}

.m-l-n-xxl {
    margin-left: -50px
}

.m-t-none {
    margin-top: 0 !important
}

.m-t-xxs {
    margin-top: 1px;
}

.m-t-xs {
    margin-top: 5px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t {
    margin-top: 15px
}

.m-t-md {
    margin-top: 20px;
}

.m-t-lg {
    margin-top: 30px;
}

.m-t-xl {
    margin-top: 40px;
}

.m-t-xxl {
    margin-top: 50px;
}

.p-t-none {
    padding-top: 0 !important
}

.p-t-xxs {
    padding-top: 1px;
}

.p-t-xs {
    padding-top: 5px;
}

.p-t-sm {
    padding-top: 10px;
}

.p-t {
    padding-top: 15px
}

.p-t-md {
    padding-top: 20px;
}

.p-t-lg {
    padding-top: 30px;
}

.p-t-xl {
    padding-top: 40px;
}

.p-t-xxl {
    padding-top: 50px;
}

.m-t-n-xxs {
    margin-top: -1px
}

.m-t-n-xs {
    margin-top: -5px
}

.m-t-n-sm {
    margin-top: -10px
}

.m-t-n {
    margin-top: -15px
}

.m-t-n-md {
    margin-top: -20px
}

.m-t-n-lg {
    margin-top: -30px
}

.m-t-n-xl {
    margin-top: -40px
}

.m-t-n-xxl {
    margin-top: -50px
}

.m-r-none {
    margin-right: 0 !important
}

.m-r-xxs {
    margin-right: 1px
}

.m-r-xs {
    margin-right: 5px
}

.m-r-sm {
    margin-right: 10px
}

.m-r {
    margin-right: 15px
}

.m-r-md {
    margin-right: 20px
}

.m-r-lg {
    margin-right: 30px
}

.m-r-xl {
    margin-right: 40px
}

.m-r-xxl {
    margin-right: 50px
}

.m-r-n-xxs {
    margin-right: -1px
}

.m-r-n-xs {
    margin-right: -5px
}

.m-r-n-sm {
    margin-right: -10px
}

.m-r-n {
    margin-right: -15px
}

.m-r-n-md {
    margin-right: -20px
}

.m-r-n-lg {
    margin-right: -30px
}

.m-r-n-xl {
    margin-right: -40px
}

.m-r-n-xxl {
    margin-right: -50px
}

.m-b-none {
    margin-bottom: 0 !important
}

.m-b-xxs {
    margin-bottom: 1px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b {
    margin-bottom: 15px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-lg {
    margin-bottom: 30px;
}

.m-b-xl {
    margin-bottom: 40px;
}

.m-b-xxl {
    margin-bottom: 50px;
}

.m-b-n-xxs {
    margin-bottom: -1px
}

.m-b-n-xs {
    margin-bottom: -5px
}

.m-b-n-sm {
    margin-bottom: -10px
}

.m-b-n {
    margin-bottom: -15px
}

.m-b-n-md {
    margin-bottom: -20px
}

.m-b-n-lg {
    margin-bottom: -30px
}

.m-b-n-xl {
    margin-bottom: -40px
}

.m-b-n-xxl {
    margin-bottom: -50px
}
 
.w-1x {
    width: 1em;
}

.w-2x {
    width: 2em;
}

.w-3x {
    width: 3em;
}

.w-xxs {
    width: 60px;
}

.w-xs {
    width: 90px;
}

.w-sm {
    width: 150px;
}

.w {
    width: 200px;
}

.w-md {
    width: 240px;
}

.w-lg {
    width: 280px;
}

.w-xl {
    width: 320px;
}

.w-xxl {
    width: 360px;
}

@media screen and (max-width: 360px) {
    .w-xxl {
        width: 90%;
    }
}

.w-full {
    width: 100%;
}

.w-auto {
    width: auto;
}

.h-auto {
    height: auto;
}

.h-full {
    height: 100%;
}

.thumb-xxl {
    width: 150px;
    display: inline-block
}

.thumb-xl {
    width: 128px;
    display: inline-block
}

.thumb-lg {
    width: 96px;
    display: inline-block
}

.thumb-md {
    width: 64px;
    display: inline-block
}

.thumb {
    width: 50px;
    display: inline-block
}

.thumb-sm {
    width: 40px;
    display: inline-block
}

.thumb-xs {
    width: 34px;
    display: inline-block
}

.thumb-xxs {
    width: 30px;
    display: inline-block
}

.thumb,
.thumb-xs,
.thumb-sm,
.thumb-md,
.thumb-lg,
.thumb-btn {
    img {
        height: auto;
        max-width: 100%;
        vertical-align: middle;
    }
}

.img-full {
    width: 100%;
    img {
        width: 100%;
    }
}

.scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    &.hover {
        overflow-y: hidden !important;
        &:hover {
            overflow: visible !important;
            overflow-y: auto !important;
        }
    }
    .smart & {
        overflow-y: auto !important;
    }
}

.scroll-x,
.scroll-y {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

.scroll-y {
    overflow-y: auto;
}

.scroll-x {
    overflow-x: auto;
}

.backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    &.fade {
        opacity: 0;
        filter: alpha(opacity=0);
    }
    &.in {
        opacity: 0.8;
        filter: alpha(opacity=80);
    }
}


/*desktop*/

@media screen and (min-width: 992px) {
    .col-lg-2-4 {
        width: 20.000%;
        float: left;
    }
}

// sm
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm.show {
        display: inherit !important;
    }
    .no-m-sm {
        margin: 0 !important;
    }
}


/*phone*/

@media (max-width: 767px) {
    .w-auto-xs {
        width: auto;
    }
    .shift {
        display: none !important;
    }
    .shift.in {
        display: block !important;
    }
    .row-2 [class*="col"] {
        width: 50%;
        float: left
    }
    .row-2 .col-0 {
        clear: none
    }
    .row-2 li:nth-child(odd) {
        clear: left;
        margin-left: 0
    }
    .text-center-xs {
        text-align: center;
    }
    .text-left-xs {
        text-align: left;
    }
    .text-right-xs {
        text-align: right;
    }
    .no-border-xs {
        border-width: 0;
    }
    .pull-none-xs {
        float: none !important;
    }
    .pull-right-xs {
        float: right !important;
    }
    .pull-left-xs {
        float: left !important;
    }
    .dropdown-menu.pull-none-xs {
        left: 0;
    }
    .hidden-xs.show {
        display: inherit !important;
    }
    .wrapper-lg,
    .wrapper-md {
        padding: 15px;
    }
    .padder-lg,
    .padder-md {
        padding-left: 15px;
        padding-right: 15px;
    }
    .no-m-xs {
        margin: 0 !important;
    }
}

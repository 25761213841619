// colors
$font-color--dark: #333;
$font-color--light: #fff;
$background--light: #eee;
$background--dark: #222;
$blue: #1f8de2;
$green: #1fe27b;
$red: #e21f3f;

// spacing
$full-space: 40px;
$half-space: 20px;

// font sizing
$font-size--xs: 10px;
$font-size--sm: 12px;
$font-size--md: 16px;
$font-size--lg: 24px;
$font-size--xl: 32px;
//@import url("https://fonts.googleapis.com/css?family=Open+Sans");

// .userMain {
//   // display: contents;
// }

.userMain .userBlock {
  float: left;
  width: 100%;
  box-shadow: 0px 0px 23px -3px #ccc;
  padding-bottom: 12px;
  margin-bottom: 30px;
  overflow: hidden;
  background: #fff;
  margin-top: 5px;
}

.userMain .userBlock .backgrounImg {
  float: left;
  overflow: hidden;
  height: 77px;
  width: 100%;
  background: #af322a;
}

.userMain .userBlock .backgrounImg.yellow {
  background: linear-gradient(#e6ba29 0%, #a7871e 100%);
}

.userMain .userBlock .backgrounImg.blue {
  background: linear-gradient(#2989d8 0%, #1e5799 100%);
}

.userMain .userBlock .backgrounImg.green {
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
}

.userMain .userBlock .backgrounImg.greendark {
  background: linear-gradient(#4a9e44 0%, #226301 100%);
}

.userMain .userBlock .backgrounImg.purple {
  background: linear-gradient(#e65e95 0%, #91103f 100%);
}

.userMain .userBlock .backgrounImg.red {
  background: #af322a !important;
}

.userMain .userBlock .backgrounImg img {
  width: auto;
}

.userMain .userBlock .userImg {
  text-align: center;
}

.userMain .userBlock .userImg img {
  width: 105px;
  height: 80px;
  margin-top: -39px;
  border-radius: 11px;
  border: 5px solid #fff;
  background: aliceblue;
}

.userMain .userBlock .userDescription > div:first-child {
  text-align: center;
  height: 57px;
}

.userMain .userBlock .userDescription div h5 {
  margin-bottom: 2px;
  font-weight: 600;
}
.userMain .userBlock .userDescription div h5 a div {
  margin-top: 8px;
}

.userMain .userBlock .userDescription div p {
  margin-bottom: 5px;
  min-height: 29px;
}

.userMain .userBlock .userDescription .btn {
  padding: 0px 23px 0px 23px;
  height: 22px;
  border-radius: 0;
  font-size: 12px;
  background: #0198dd;
  color: #fff;
}

.userMain .userBlock .userDescription .btn:hover {
  opacity: 0.7;
}

.userMain .userBlock .followrs {
  display: inline-flex;
  margin-right: 10px;
  border-right: 1px solid #ccc;
  padding-right: 10px;
}

.userMain .userBlock .followrs .number {
  font-size: 15px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: -1px;
}

.shape {
  border-style: solid;
  border-width: 0 85px 50px 0;
  height: 0px;
  width: 0px;
  position: absolute;
  right: 8px;
  -ms-transform: rotate(360deg);
  /* IE 9 */
  -o-transform: rotate(360deg);
  /* Opera 10.5 */
  -webkit-transform: rotate(360deg);
  /* Safari and Chrome */
  transform: rotate(360deg);
}

.userBlock {
  border: 1px solid;
}

.userBlock.actif {
  border-color: #428bca;
}

.userBlock.inactif {
  border-color: #d9534f;
}

.userBlock .shape.actif {
  border-color: transparent #428bca transparent transparent;
  border-color: rgba(255, 255, 255, 0) #428bca rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
}

.userBlock .shape.inactif {
  border-color: transparent #d9534f transparent transparent;
  border-color: rgba(255, 255, 255, 0) #d9534f rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
}

.shape-text {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  right: -45px;
  top: 0px;
  white-space: nowrap;
  -ms-transform: rotate(30deg);
  /* IE 9 */
  -o-transform: rotate(360deg);
  /* Opera 10.5 */
  -webkit-transform: rotate(30deg);
  /* Safari and Chrome */
  transform: rotate(30deg);
}

.ribbon {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon > span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 20px;
  left: -19px;
}

.ribbon > span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
}

.ribbon > span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
}

.ribbon.green > span {
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
}

.ribbon.green > span::before,
.ribbon.green > span::after {
  border-left: 3px solid #79a70a;
  border-top: 3px solid #79a70a;
}

.ribbon.yellow > span {
  background: #a7871e;
  background: linear-gradient(#e6ba29 0%, #a7871e 100%);
}

.ribbon.yellow > span::before,
.ribbon.yellow > span::after {
  border-left: 3px solid #a7871e;
  border-top: 3px solid #a7871e;
}

.ribbon.blue > span {
  background: #1e5799;
  background: linear-gradient(#2989d8 0%, #1e5799 100%);
}

.ribbon.blue > span::before,
.ribbon.blue > span::after {
  border-left: 3px solid #1e5799;
  border-top: 3px solid #1e5799;
}

.ribbon.purple > span {
  background: #91103f;
  background: linear-gradient(#e65e95 0%, #91103f 100%);
}

.ribbon.purple > span::before,
.ribbon.purple > span::after {
  border-left: 3px solid #91103f;
  border-top: 3px solid #91103f;
}

/*  greendark */
.ribbon.greendark > span {
  background: #226301;
  background: linear-gradient(#4a9e44 0%, #226301 100%);
}

.ribbon.greendark > span::before,
.ribbon.greendark > span::after {
  border-left: 3px solid #226301;
  border-top: 3px solid #226301;
}

.ratings {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  color: #b1b1b1;
  overflow: hidden;
  margin-top: -5px;
}

.full-stars {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #fde16d;
}

.empty-stars:before,
.full-stars:before {
  content: "\2605\2605\2605\2605\2605";
  font-size: 14pt;
}

.empty-stars:before {
  -webkit-text-stroke: 1px #848484;
}

.full-stars:before {
  -webkit-text-stroke: 1px orange;
}

/* Webkit-text-stroke is not supported on firefox or IE */
/* Firefox */
@-moz-document url-prefix() {
  .full-stars {
    color: #ecbe24;
  }
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

/**
 *
 */
.emp-profile {
  padding: 3%;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 1.5rem;
  background: #fff;
}

.profile-img {
  text-align: center;
}

.profile-img img {
  width: 70%;
  height: 100%;
}

.profile-img .file {
  position: relative;
  overflow: hidden;
  width: 70%;
  border: none;
  border-radius: 0;
  font-size: 15px;
  cursor: pointer !important;
  background: rgba(51, 51, 51, 0.658);
}

.profile-img .file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  cursor: pointer !important;
}

.profile-head div div h3 {
  color: #333;
}

.profile-head div div h4 {
  color: #0062cc;
}

.btn_menu_success {
  position: relative;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#05ac50+0,21dd72+50,05c44e+51,05ac50+71,05ac50+100 */
  background: #05ac50;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #05ac50 0%, #21dd72 50%, #05c44e 51%, #05ac50 71%, #05ac50 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #05ac50 0%, #21dd72 50%, #05c44e 51%, #05ac50 71%, #05ac50 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #05ac50 0%, #21dd72 50%, #05c44e 51%, #05ac50 71%, #05ac50 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#05ac50', endColorstr='#05ac50', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  background-size: 400% 400%;
  // -webkit-animation: AnimationName 15s ease infinite;
  // -moz-animation: AnimationName 15s ease infinite;
  // animation: AnimationName 15s ease infinite;
  // -webkit-animation: AnimationName 15s ease infinite;
  // -moz-animation: AnimationName 15s ease infinite;
  // animation: AnimationName 15s ease infinite;
  border: 1px solid transparent;
  margin: 6px 3px;
  min-width: 95px;
}

.btn_menu_success > span.badge_actif {
  position: absolute;
  right: 0;
  top: -15px;
}

.btn_menu_success > span.badge_inactif {
  position: absolute;
  right: 29px;
  top: -15px;
}

.btn_menu_primary {
  position: relative;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5a7ce2+0,8283e8+50,5c5de8+51,565bd8+71,575cdb+100 */
  background: #5a7ce2;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #5a7ce2 0%, #8283e8 50%, #5c5de8 51%, #565bd8 71%, #575cdb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #5a7ce2 0%, #8283e8 50%, #5c5de8 51%, #565bd8 71%, #575cdb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #5a7ce2 0%, #8283e8 50%, #5c5de8 51%, #565bd8 71%, #575cdb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5a7ce2', endColorstr='#575cdb', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  background-size: 400% 400%;
  // -webkit-animation: AnimationName 15s ease infinite;
  // -moz-animation: AnimationName 15s ease infinite;
  // animation: AnimationName 15s ease infinite;
  // -webkit-animation: AnimationName 15s ease infinite;
  // -moz-animation: AnimationName 15s ease infinite;
  // animation: AnimationName 15s ease infinite;
  border: 1px solid transparent;
  margin: 6px 3px;
  min-width: 110px;
}

.btn_menu_primary > span.actif:first-child {
  position: absolute;
  right: 0;
  top: -15px;
}

.btn_menu_primary > span.inactif:nth-child(2) {
  position: absolute;
  right: 29px;
  top: -15px;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 31%;
  }

  50% {
    background-position: 100% 70%;
  }

  100% {
    background-position: 0% 31%;
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 31%;
  }

  50% {
    background-position: 100% 70%;
  }

  100% {
    background-position: 0% 31%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 31%;
  }

  50% {
    background-position: 100% 70%;
  }

  100% {
    background-position: 0% 31%;
  }
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.custom-radios div {
  display: inline-block;
}

.custom-radios input[type="radio"] {
  display: none;
}

.custom-radios input[type="radio"] + label {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.custom-radios input[type="radio"] + label span {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 44px;
}

.custom-radios input[type="radio"] + label span img {
  opacity: 0;
  transition: all 0.3s ease;
}

.custom-radios input[type="radio"]#color-1 + label span {
  background-color: #2ecc71;
}

.custom-radios input[type="radio"]#color-2 + label span {
  background-color: #3498db;
}

.custom-radios input[type="radio"]#color-3 + label span {
  background-color: #f1c40f;
}

.custom-radios input[type="radio"]#color-4 + label span {
  background-color: #e74c3c;
}

.custom-radios input[type="radio"]:checked + label span {
  opacity: 1;
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg) center center no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block;
}
/* form starting stylings ------------------------------- */
.new_group {
  position: absolute;
  right: 0;
  top: -32px;
}
.new_group > input {
  font-size: 18px;
  /* padding: 10px 10px 10px 5px; */
  padding: 5px 5px 5px 5px;
  display: block;
  width: 300px;
  border: none;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.new_group > input:focus {
  outline: none;
}
/* LABEL ======================================= */
.new_group > label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.new_group > input:focus ~ label {
  top: -20px;
  font-size: 14px;
  color: #5264ae;
}
/* BOTTOM BARS ================================= */
.new_group > .bar {
  position: relative;
  display: block;
  width: 300px;
}
.new_group > .bar:before,
.new_group > .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.new_group > .bar:before {
  left: 50%;
}
.new_group > .bar:after {
  right: 50%;
}
/* active state */
.new_group > input:focus ~ .bar:before,
.new_group > input:focus ~ .bar:after {
  width: 50%;
}
/* HIGHLIGHTER ================================== */
.new_group > .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.new_group > input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

.fa_search_user {
  position: absolute;
  right: 17px;
  top: 11px;
  font-size: 18px;
  color: blue;
  font-weight: bold;
}

/**
 * Modal design for user profile
 */
.dev_nav_center {
  display: flex;
  flex-direction: row;
  justify-content: start;
  border-bottom: 1px solid #ddd;
}

.dev_nav_center > li > a {
  font-size: 12px;
  color: #495057;
  font-weight: 600;
  padding: 2px 10px 3px 0px;
  margin-right: 10px;
  margin-left: 10px;
  border: none !important;
}

.dev_nav_center > li > a {
  text-decoration: none;
  padding-bottom: 5px;
}

.dev_nav_center > li.active {
  border-bottom: 3px solid blue;
}

// input-2
/* form starting stylings ------------------------------- */
.input-2 {
  position: relative;
  width: 100%;
}
.input-2 > input {
  font-size: 15px;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  display: block;
  width: 100%;
  border: none;
}
.input-2 > input:focus {
  outline: none;
}
/* LABEL ======================================= */
.input-2 > label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.input-2 > input:focus ~ label,
.input-2 > input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #5264ae;
}
/* BOTTOM BARS ================================= */
.input-2 > .bar {
  position: relative;
  display: block;
  width: 100%;
}
.input-2 > .bar:before,
.input-2 > .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.input-2 > .bar:before {
  left: 50%;
}
.input-2 > .bar:after {
  right: 50%;
}
/* active state */
.input-2 > input:focus ~ .bar:before,
.input-2 > input:focus ~ .bar:after,
.input-2 > input:valid ~ .bar:before,
.input-2 > input:valid ~ .bar:after {
  width: 50%;
}
/* HIGHLIGHTER ================================== */
.input-2 > .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.input-2 > input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}
.input-2 > .fa.fa_input {
  position: absolute;
  right: 5px;
  top: 9px;
  font-size: 17px;
  color: blue;
  font-weight: bold;
}
.input-2 > input {
  background: aliceblue !important;
}
.input-2 {
  position: relative;
  display: block;
  width: 100%;
}

.select-2 {
  background: aliceblue !important;
  border: none;
}

.select-2:focus,
.select-2:valid {
  border: none;
  border-bottom: 2px solid #5163ae;
}
.select-2:before,
.select-2:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.select-2:before {
  left: 50%;
}
.select-2:after {
  right: 50%;
}

.label_select-2 {
  left: 10px;
  position: absolute;
  top: -19px;
  color: #5163ae;
}

.info-header {
  border-bottom: 2px solid blue;
  margin-bottom: 11px;
  min-height: 72px;
  border-radius: 20px;
  margin-right: 15px;
}

.info-equipement > div {
  font-size: 14px;
  line-height: 32px;
}

.info-equipement > h5 {
  margin-top: 22px;
  color: blue;
}

.btn-gamme{
  width: 100%;
  margin-top: 30px;
}
.sans-depot{
  border : 1px solid red!important;
  background: rgba(238, 110, 110, 0.61)!important;
}